import React, { useEffect, useState } from 'react'
import { CirclePie } from 'salad-ui.chart'
import { Button, CustomIcon, palette, Tooltip } from '@frontend/design-system'
import {
  SidebarProjectQuery,
  useCompleteOnboardingChecklistMutation,
  useOrganizationIntendedCiProvidersQuery,
  useProjectIntendedCiProvidersQuery,
} from '~/graphql-codegen-operations.gen'
import styles from './module.OnboardingChecklist.scss'
import { Link } from '@reach/router'
import { CurrentProject } from '~/navs/types'
import { ciProviderData, defaultCiProviderInfo } from '~/projects/constants'
import { InviteLink } from '~/common/invite-link/InviteLink'
import { IconActionDeleteXlarge } from '@cypress-design/react-icon'

interface OnboardingChecklistProps {
  organization: SidebarProjectQuery['project']['organizationInfo'] | null
  project?: CurrentProject
}

interface OnboardingChecklistItem {
  key: string
  label: string
  completed: boolean
  linkTo?: string
  linkToExtern?: string
}

const OnboardingChecklist: React.FC<OnboardingChecklistProps> = ({
  organization,
  project,
}) => {
  const { data: organizationIntendedCiProviders } =
    useOrganizationIntendedCiProvidersQuery({
      variables: organization ? { orgId: organization.id } : undefined,
      skip: !organization,
    })
  const { data: projectIntendedCiProviders } =
    useProjectIntendedCiProvidersQuery({
      variables: project ? { projectId: project.id } : undefined,
      skip: !project,
    })
  const [completeOnboardingChecklist] = useCompleteOnboardingChecklistMutation()

  const [items, setItems] = useState<OnboardingChecklistItem[]>([])
  const [completionPercent, setCompletionPercent] = useState(0)
  const [intendedCiProvider, setIntendedCiProvider] = useState(
    defaultCiProviderInfo
  )

  const updateIntendedProviderData = (intendedCiProviders) => {
    if (!intendedCiProviders) {
      return false
    }

    const ciProviders = intendedCiProviders
      .map((c) => c.key)
      .sort((a, b) => a.localeCompare(b))

    for (const ciProvider of ciProviders) {
      const data = ciProviderData[ciProvider]
      if (data) {
        setIntendedCiProvider(data)
        return true
      }
    }

    return false
  }

  useEffect(() => {
    if (
      !(
        project &&
        updateIntendedProviderData(
          projectIntendedCiProviders?.project.intendedCIProviders
        )
      ) &&
      !updateIntendedProviderData(
        organizationIntendedCiProviders?.organization.intendedCIProviders
      )
    ) {
      setIntendedCiProvider(defaultCiProviderInfo)
    }
  }, [project, projectIntendedCiProviders, organizationIntendedCiProviders])

  useEffect(() => {
    if (organization) {
      const newItems = [
        {
          key: 'createdProject',
          label: 'Create a project',
          completed: organization.projects.totalCount > 0,
        },
        {
          key: 'recordedRun',
          label: 'Record a run',
          completed: organization.projects.nodes.some(
            (project) => project.latestRun
          ),
          linkTo: organization?.projects.totalCount
            ? `/projects/${
                project?.id ?? organization?.projects.nodes[0]?.id
              }/runs`
            : undefined,
        },
        {
          key: `connectedCI-${intendedCiProvider.key}`,
          label: `Run in ${intendedCiProvider.title}`,
          completed:
            organization.isUsingCI ||
            (organization.projects.nodes.length
              ? Math.max(
                  ...organization.projects.nodes.map(
                    (p) => p.approximateRunCount ?? 0
                  )
                )
              : 0) >= 5,
          linkToExtern: intendedCiProvider.link,
        },
      ]

      setItems(newItems)

      setCompletionPercent(
        Math.round(
          (newItems
            .map((item) => (item.completed ? 1 : 0) as number)
            .reduce((a, v) => a + v, 0) /
            newItems.length) *
            100
        )
      )
    }
  }, [organization, project, intendedCiProvider])

  const onClose = () => {
    if (organization) {
      completeOnboardingChecklist({
        variables: {
          orgId: organization.id,
        },
      }).then(() => {
        setItems([])
      })
    }
  }

  if (!items.length || !items[0].completed) {
    return <div className="onboarding-checklist-hidden" />
  }

  let foundActiveItem = false

  const onboardingCompleted = completionPercent === 100

  return (
    <div data-cy="onboarding-checklist" className={styles.onboardingChecklist}>
      <div
        className={`${styles.onboardingChecklistHeader} ${
          onboardingCompleted ? styles.onboardingChecklistHeaderCompleted : ''
        }`}
      >
        <CirclePie
          width={44}
          height={44}
          strokeWidth={5}
          labelFontSize="12"
          percent={completionPercent}
          strokeColor={palette.jade400}
          labelColor={palette.jade400}
          fill="none"
        />
        {onboardingCompleted ? (
          <>
            <div className={styles.onboardingComplete}>Onboarding complete</div>
            <Button
              type="button"
              aria-label="Close"
              bsStyle="link"
              data-cy="close-onboarding-checklist"
              className={styles.onboardingClose}
              onClick={onClose}
            >
              <IconActionDeleteXlarge />
            </Button>
          </>
        ) : (
          <>
            <div>Onboarding progress</div>{' '}
            <Tooltip
              placement="left"
              overlay={
                <span>
                  Click on each step to learn more about how
                  <br />
                  to configure your Cypress account, unlocking
                  <br />
                  automated insights to help manage your test suite.
                </span>
              }
            >
              <CustomIcon name="info" variant="small" />
            </Tooltip>
          </>
        )}
      </div>
      {onboardingCompleted ? (
        <div className={styles.inviteUsers}>
          Great work! Now invite your teammates to share your test suite
          analytics.
          <br />
          <InviteLink id={organization?.id} />
        </div>
      ) : (
        items.map((item) => {
          const isNext = !foundActiveItem && !item.completed

          if (isNext) {
            foundActiveItem = true
          }

          const className = `${styles.onboardingChecklistItem} ${
            item.completed
              ? styles.onboardingChecklistItemCompleted
              : isNext
                ? styles.onboardingChecklistItemActive
                : ''
          }`

          const icon = item.completed ? (
            <i className={`fa fa-check ${styles.checkmarkIcon}`} />
          ) : isNext ? (
            <i className={`fa fa-circle-o ${styles.circleIcon}`} />
          ) : (
            <i className="fa fa-circle-o" />
          )

          const content = (
            <>
              {icon}
              {item.label}
              {isNext && (
                <CustomIcon
                  name="chevron-right"
                  height={14}
                  width={14}
                  className={styles.chevronRightIcon}
                />
              )}
            </>
          )

          if (item.linkTo) {
            return (
              <Link
                key={item.key}
                className={className}
                data-pendo={`onboarding-checklist-${item.key}`}
                to={item.linkTo}
              >
                {content}
              </Link>
            )
          }

          if (item.linkToExtern) {
            return (
              <a
                key={item.key}
                className={className}
                data-pendo={`onboarding-checklist-${item.key}`}
                href={item.linkToExtern}
                target="_blank"
              >
                {content}
              </a>
            )
          }

          return (
            <div
              key={item.key}
              className={className}
              data-pendo={`onboarding-checklist-${item.key}`}
            >
              {content}
            </div>
          )
        })
      )}
    </div>
  )
}

export default OnboardingChecklist
