import { assign } from 'lodash'
import { action, observable } from 'mobx'
import { RunInstanceStatusEnum } from '~/graphql-codegen-operations.gen'
import Spec from '~/specs/spec-model'

export default class Machine {
  @observable id
  @observable status: RunInstanceStatusEnum = 'UNCLAIMED'
  @observable specs: Spec[] = []
  @observable wallClockDuration
  @observable provisioningTime = 0
  @observable machineDuration = 0

  constructor(options) {
    assign(this, options)
  }

  @action addSpec(spec) {
    this.specs.push(spec)
  }

  /**
   * The total time this machine spent running specs, from the time the run
   * started until the time the last completed post-processing.
   *
   * @returns {number} duration in milliseconds
   */
  get totalCpuTime() {
    let result = this.provisioningTime

    for (const spec of this.specs) {
      result +=
        +new Date(spec.postProcessingCompletedAt) - +new Date(spec.claimedAt)
    }

    return result
  }

  get currentDuration() {
    return this.machineDuration
  }

  /**
   * The "dashboard-friendly" time the machine spent running specs. Similar to
   * `totalCpuTime`, but cuts off the time the first spec spent launching, and
   * the time the last spec spent post-processing.
   *
   * @returns {number} duration in milliseconds
   */
  get totalDuration() {
    const first = this.specs[0]
    const last = this.specs[this.specs.length - 1]
    const initialClaimDelta =
      +new Date(first.wallClockStartedAt) - +new Date(first.claimedAt)
    const trailingPostProcessingDelta =
      +new Date(last.postProcessingCompletedAt) -
      +new Date(last.wallClockEndedAt)

    return this.totalCpuTime - initialClaimDelta - trailingPostProcessingDelta
  }

  @action addDuration(duration: number) {
    if (!this.machineDuration) {
      this.machineDuration += this.provisioningTime
    }
    this.machineDuration += duration
  }
}
