import type { NodeMap } from '../Replay/nodeMap'
import type { EventsPayload } from '@packages/app-capture-protocol/src/db/schemas/latest'
import { getElementFromPayload } from '../getElementFromPayload'
import type { CanvasResources } from '../useDomReplay'

declare global {
  interface Window {
    IN_DISCOVERY: boolean
  }
}

export const applyCanvasSnapshot = ({
  ctx,
  payload,
}: {
  ctx: {
    nodeMap: React.MutableRefObject<NodeMap>
    canvasResources?: CanvasResources
  }
  payload: EventsPayload['cdp']['dom:canvas-snapshot']
}) => {
  // if we didn't load any canvas assets (e.g. the test-replay-canvas feature flag is off)
  // we can't apply the snapshot
  if (!ctx.canvasResources?.domCanvasAssets) {
    return
  }

  const canvasElement = getElementFromPayload(
    ctx.nodeMap.current,
    payload
  ) as HTMLCanvasElement

  if (canvasElement) {
    // if we are in discovery, we don't need to apply the snapshot since we don't need
    // to do any processing to it and just need it to be displayed in the snapshot
    if (window.IN_DISCOVERY) {
      canvasElement.setAttribute(
        'data-cy-replay-canvas-hash',
        payload.hashValue
      )

      return
    }

    const imageData =
      ctx.canvasResources?.domCanvasAssets?.imageData[payload.hashValue]

    if (!imageData) {
      return
    }

    try {
      const canvasCtx = canvasElement.getContext('2d')
      canvasCtx?.drawImage(imageData.bitmap, 0, 0)
    } catch (e) {
      console.error('Failed to apply canvas snapshot', e)
    }
  }
}
