import React from 'react'
import _ from 'lodash'
import {
  IconTestingTypeComponentSolid,
  IconTechnologyGitBranches,
  IconViewPieChartAlt,
} from '@cypress-design/react-icon'
import { ProgressiveProjectSetup } from '../common/project-setup/progressive-ui/ProgressiveProjectSetup'
import { EnableGitProvider } from './EnableGitProvider'
import { MessagingPlatform } from './MessagingPlatform'
import { InviteTeam } from './InviteTeam'
import { ReviewLatestRun } from './ReviewLatestRun'
import { ViewLatestRuns } from './ViewLatestRuns'
import { ViewBranches } from './ViewBranches'
import { ViewTopFlakyTests } from './ViewTopFlakyTests'
import { ViewProjectAnalytics } from './ViewProjectAnalytics'
import { ViewEnterpriseReporting } from './ViewEnterpriseReporting'
import { ViewDataExtractApi } from './ViewDataExtractApi'
import { ViewParallelization } from './ViewParallelization'
import { ViewGroupedRuns } from './ViewGroupedRuns'
import { EnableAutoCancellation } from './EnableAutoCancellation'
import { EnableSpecPrioritization } from './EnableSpecPrioritization'
import { ViewBranchReview } from './ViewBranchReview'
import styles from './module.GroupList.scss'
import clsx from 'clsx'

export const levelOne = [
  {
    title: 'Record your first run to Cypress Cloud from CI',
    description:
      'Connect your project to Cypress Cloud by recording your Cypress tests from CI.',
    component: (props) => (
      <ProgressiveProjectSetup
        supportedCIProviders={[]}
        intendedCIProviders={[]}
        isOnboarding
        {...props}
      />
    ),
    isActiveStep: true,
    isCompleted: false,
    trackingKey: 'hasRecordedRun',
    shouldHideForPaidPlans: true,
  },
  {
    title: 'Enable source control integrations',
    description:
      'Streamline your git workflow by enabling status checks and pull request comments directly from Cypress.',
    learnMore: 'http://on.cypress.io/github-integration',
    component: (props) => <EnableGitProvider {...props} />,
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasEnabledSourceControlIntegration',
    shouldHideForPaidPlans: false,
  },
  {
    title: 'Integrate messaging platform',
    description:
      'Enhance team collaboration, receive immediate test failure notifications, and increase visibility into your test suite’s performance by integrating Cypress with Slack and Teams.',
    learnMore: 'https://on.cypress.io/slack-integration',
    component: (props) => <MessagingPlatform {...props} />,
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasEnabledOtherIntegration',
    shouldHideForPaidPlans: false,
  },
  {
    title: 'Invite team',
    description:
      'Boost productivity and collaboration in Cypress Cloud by inviting up to 50 team members.',
    learnMore: 'https://on.cypress.io/dashboard-users#Invite-users',
    component: (props) => <InviteTeam {...props} />,
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasMultipleMembers',
    shouldHideForPaidPlans: false,
  },
  {
    title: 'Debug failed or flaky tests in Test Replay',
    description:
      'Time travel back to the point of failure with Test Replay. Inspect the DOM, network events, and console logs of your application from your tests exactly as they ran in CI.',
    learnMore: 'https://on.cypress.io/test-replay',
    component: (props) => <ReviewLatestRun {...props} />,
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasVisitedTestReplay',
    shouldHideForPaidPlans: true,
  },
]

export const levelTwo = [
  {
    title: 'Record 10 runs in CI',
    description:
      'Increase your test coverage and get valuable insights by triggering new runs in CI.',
    component: (props) => <ViewLatestRuns {...props} />,
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasMultipleRecordedRunsInCi',
    shouldHideForPaidPlans: true,
  },
  {
    title: 'Record runs from multiple branches',
    description:
      'Get valuable insights across branches by recording a new run to Cypress Cloud from a different branch.',
    component: (props) => <ViewBranches {...props} />,
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasMultipleBranches',
    shouldHideForPaidPlans: true,
  },
  {
    title: 'Identify top flaky tests with Flake Detection',
    description:
      'Cypress uses `retries` to automatically identify flaky tests.',
    component: (props) => <ViewTopFlakyTests {...props} />,
    learnMore: 'https://on.cypress.io/test-retries',
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasEnabledRetriesForFlake',
    shouldHideForPaidPlans: false,
  },
  {
    title: 'Identify trends and patterns with Project Analytics',
    description:
      'Proactively resolve critical areas of tech debt building up throughout your test suite by identifying new insights with Project Analytics.',
    component: (props) => <ViewProjectAnalytics {...props} />,
    learnMore: 'https://on.cypress.io/project-analytics',
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasUsedProjectAnalytics',
    shouldHideForPaidPlans: true,
  },
  {
    title: 'Review Enterprise Reporting',
    description:
      'Spot trends and anomalies, track usage, and govern your overall test suites across all projects in your organization. Data is refreshed daily at midnight UTC.',
    component: (props) => <ViewEnterpriseReporting {...props} />,
    learnMore: 'https://on.cypress.io/enterprise-analytics',
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasUsedEnterpriseReporting',
    shouldHideForPaidPlans: false,
  },
  {
    title: 'Review Data Extract API',
    description:
      'Incorporate the data from Enterprise Reporting into your BI reporting platform. All test details through midnight UTC can be extracted from Cypress Cloud.',
    component: (props) => <ViewDataExtractApi {...props} />,
    learnMore: 'https://on.cypress.io/cloud-api',
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasUsedDataExtractApi',
    shouldHideForPaidPlans: false,
  },
  {
    title: 'Cypress UI Coverage',
    description:
      'Identify any testing gaps within your application.  As a new product from Cypress, UI Coverage uses the information available from your existing Cypress tests, with no setup, code changes, or performance penalty.  Cypress UI Coverage can boost confidence in code deployments, simplify test coverage analysis, and empower your team to deliver high-value applications with assurance.',
    component: () => null,
    learnMore:
      'http://on.cypress.io/ui-coverage-trial?utm_source=cypress-cloud&utm_medium=onboarding&utm_content=Cypress%20UI%20Coverage',
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasVisitedUiCoverageLink',
    shouldHideForPaidPlans: false,
    completeOnLearnMore: true,
  },
  {
    title: 'Cypress Accessibility',
    description:
      'Generate accessibility reports from every Cypress run.  As a new product from Cypress, Accessibility uses the information available from your existing Cypress tests, with no setup, code changes, or performance penalty.  Cypress Accessibility provides easily sharable visual and interactive reports for debugging issues, right next to your test results in Cypress Cloud.',
    component: () => null,
    learnMore:
      'http://on.cypress.io/accessibility-trial?utm_source=cypress-cloud&utm_medium=onboarding&utm_content=Cypress%20Accessibility',
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasVisitedAccessibilityLink',
    shouldHideForPaidPlans: false,
    completeOnLearnMore: true,
  },
]

export const levelThree = [
  {
    title: 'Run recorded tests in parallel across multiple machines',
    description:
      'Reduce run times and increase release velocity in CI with Parallelization.',
    component: (props) => <ViewParallelization {...props} />,
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasRecordedRunWithParallelization',
    shouldHideForPaidPlans: false,
  },
  {
    title: 'Group multiple Cypress runs together into a single run',
    description:
      'Improve branch review workflows by grouping together multiple cypress runs.',
    component: (props) => <ViewGroupedRuns {...props} />,
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasUsedMultipleRunGroups',
    shouldHideForPaidPlans: true,
  },
  {
    title: 'Enable Auto Cancellation',
    description:
      'Reduce feedback time and CI resource costs with Auto Cancellation.',
    component: (props) => <EnableAutoCancellation {...props} />,
    learnMore: 'https://on.cypress.io/auto-cancellation',
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasRecordedRunWithAutoCancellation',
    shouldHideForPaidPlans: false,
  },
  {
    title: 'Enable Spec Prioritization',
    description:
      'Speed up the debugging process by running failed specs first with Spec Prioritization.',
    component: (props) => <EnableSpecPrioritization {...props} />,
    learnMore: 'https://on.cypress.io/spec-prioritization',
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasRecordedRunWithSpecPrioritization',
    shouldHideForPaidPlans: false,
  },
  {
    title: 'Review impact with Branch Review',
    description:
      'Prevent regressions by identifying the impact of each PR on your test suite.',
    component: (props) => <ViewBranchReview {...props} />,
    isActiveStep: false,
    isCompleted: false,
    trackingKey: 'hasUsedBranchReview',
    shouldHideForPaidPlans: true,
  },
]

export const groups = [
  {
    icon: (isActiveGroup: boolean) => {
      const icon = (isActive: boolean) => {
        if (isActive) {
          return (
            <IconTestingTypeComponentSolid
              size="24"
              height={32}
              width={32}
              fillColor="indigo-400"
              strokeColor="indigo-600"
            />
          )
        }

        return (
          <IconTestingTypeComponentSolid
            size="24"
            height={32}
            width={32}
            fillColor="gray-400"
            strokeColor="gray-600"
          />
        )
      }

      return (
        <div
          className={clsx(
            styles.groupButton,
            isActiveGroup && styles.groupButtonActive
          )}
        >
          {icon(isActiveGroup)}
        </div>
      )
    },
    title: 'Level One',
    description: 'Set up the fundamentals of your organization',
    isCollapsed: false,
    rows: levelOne,
  },
  {
    icon: (isActiveGroup: boolean) => {
      const icon = (isActive: boolean) => {
        if (isActive) {
          return (
            <IconViewPieChartAlt
              size="24"
              height={32}
              width={32}
              fillColor="indigo-400"
              strokeColor="indigo-100"
              secondaryStrokeColor="indigo-800"
              secondaryFillColor="indigo-600"
            />
          )
        }

        return (
          <IconViewPieChartAlt
            size="24"
            height={32}
            width={32}
            fillColor="gray-400"
            strokeColor="gray-100"
            secondaryStrokeColor="gray-800"
            secondaryFillColor="gray-600"
          />
        )
      }

      return (
        <div
          className={clsx(
            styles.groupButton,
            isActiveGroup && styles.groupButtonActive
          )}
        >
          {icon(isActiveGroup)}
        </div>
      )
    },
    title: 'Level Two',
    description:
      'Gain valuable insights into your test suite and team usage at scale',
    isCollapsed: true,
    rows: levelTwo,
  },
  {
    icon: (isActiveGroup: boolean) => {
      const icon = (isActive: boolean) => {
        if (isActive) {
          return (
            <IconTechnologyGitBranches
              size="24"
              height={32}
              width={32}
              fillColor="indigo-400"
              strokeColor="indigo-100"
              secondaryFillColor="indigo-600"
            />
          )
        }

        return (
          <IconTechnologyGitBranches
            size="24"
            height={32}
            width={32}
            fillColor="gray-400"
            strokeColor="gray-100"
            secondaryFillColor="gray-600"
          />
        )
      }

      return (
        <div
          className={clsx(
            styles.groupButton,
            isActiveGroup && styles.groupButtonActive
          )}
        >
          {icon(isActiveGroup)}
        </div>
      )
    },
    title: 'Level Three',
    description:
      'Optimize your test suite quality by reviewing the impact on each PR',
    isCollapsed: true,
    rows: levelThree,
  },
]

export const allLevels = [...levelOne, ...levelTwo, ...levelThree]
