import { CustomIcon } from '@frontend/design-system/src/components/custom-icons/CustomIcon'
import React, { ButtonHTMLAttributes, FC, FormEvent } from 'react'
import styles from './module.Switch.scss'
import cs from 'clsx'

interface SwitchProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onChange' | 'size'> {
  buttonRef?: React.Ref<HTMLButtonElement>
  checked: boolean
  onChange: (value: boolean) => void
  size?: 'x16' | 'x24' | 'x32' | 'x48'
  'data-cy'?: string
  className?: string
}

const Switch: FC<SwitchProps> = ({
  buttonRef,
  checked,
  onChange,
  size = 'x48',
  'data-cy': dataCy,
  className,
  ...rest
}) => {
  const onClick = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onChange(!checked)
  }

  return (
    <span className={cs(styles.container, className)}>
      <button
        {...rest}
        ref={buttonRef}
        data-cy={dataCy}
        aria-checked={checked}
        className={cs('switch', styles.switch, styles[size])}
        role="switch"
        onClick={onClick}
      >
        <span className={styles.indicator} />
      </button>
      {rest.disabled && (
        <span className={styles.locked}>
          <CustomIcon name="security-lock-locked" size="x16" />
          <div>Locked</div>
        </span>
      )}
    </span>
  )
}

export { Switch }
