import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'
import cs from 'clsx'

type CommitShaProps = {
  sha: string | null | undefined
  url: string | null | undefined
  hideIcon?: boolean
  className?: string
}

export const CommitSha: FunctionComponent<CommitShaProps> = ({
  sha,
  url,
  hideIcon = false,
  className,
}) => {
  const icon = hideIcon ? null : <CustomIcon name="commit" variant="small" />

  if (!sha) {
    return (
      <>
        {icon}
        <span className="text-muted">Not provided</span>
      </>
    )
  }

  const shortenedSha = sha.slice(0, 7)

  return (
    <div className={cs(['commit-sha', className])}>
      {icon}
      {url ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {shortenedSha}
        </a>
      ) : (
        <span>{shortenedSha}</span>
      )}
    </div>
  )
}
