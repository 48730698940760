/* global ChargeBee */
import './lib/locale'
import './main.scss'

import { ApolloProvider } from '@apollo/react-hooks'
import { ConfirmationServiceProvider } from '@frontend/react'
import * as Sentry from '@sentry/browser'
import { configure as configureMobx } from 'mobx'
import React from 'react'
import { RecoilRoot } from 'recoil'
import { render } from 'react-dom'

import { dashboardApolloClient } from './gql/dashboardApolloClient'
import { features } from './lib/feature-flags'
import momentOverrides from './lib/overrides/moment-overrides'
import setupErrorTracking from './lib/error-tracking'
import { SidebarStateProvider } from './navs/sidebarService'
import ReachRouter from './reach-router'

if (window.Cypress) {
  import('./lib/percy.scss')
}

// store as a global for stubbing in tests
window.ChargeBee = ChargeBee
window.Sentry = window.Sentry || Sentry
window.features = features

configureMobx({ isolateGlobalState: true, enforceActions: 'observed' })
setupErrorTracking()
momentOverrides()

render(
  <RecoilRoot>
    <ApolloProvider client={dashboardApolloClient}>
      <ConfirmationServiceProvider>
        <SidebarStateProvider>
          <ReachRouter style={{ height: '100%' }} />
        </SidebarStateProvider>
      </ConfirmationServiceProvider>
    </ApolloProvider>
  </RecoilRoot>,
  document.getElementById('app')
)
