import type { HrefResolver } from './Replay/hrefResolver'
import type { NodeMap } from './Replay/nodeMap'
import { smallTransparentImage } from './autMaskPlaceholder'
import { warnOnce } from './Replay/warnOnce'
import { ensureHrefLikeUrl } from './ensureHrefLikeUrl'
import {
  Base64AssetMetadata,
  toBase64Asset,
  updateMetadataWithBase64Asset,
} from './toBase64Asset'
import { rewriteCss } from './rewriteCss'
import { safelyApplyAttribute } from './safelyApplyAttribute'
import { DBNodePayload, NetworkResources, addDocType } from './useDomReplay'
import { safelyCreateElement } from './safelyCreateElement'
import { isEmpty, merge } from 'lodash'
import { safelyApplyProperty } from './safelyApplyProperty'

function applyTitleToMaskedNodes(
  createdNode?: HTMLElement,
  isChildOfSvg?: boolean,
  hasCapturedAndProcessedCanvasAssets?: boolean
) {
  // Unhandled node types will not have a createdNode.
  // Bail early to be safe here.
  if (!createdNode) {
    return
  }

  const nodeType =
    typeof createdNode.tagName === 'string'
      ? createdNode.tagName.toUpperCase()
      : undefined

  if (!nodeType) {
    return
  }

  // do not add title to "image/svg+xml" objects
  // since we do support support those in replay.
  if (nodeType === 'OBJECT') {
    if ('type' in createdNode && createdNode.type === 'image/svg+xml') {
      return
    }
  }

  if (
    ['VIDEO', 'OBJECT'].includes(nodeType) ||
    (nodeType === 'CANVAS' && !hasCapturedAndProcessedCanvasAssets)
  ) {
    const msg = 'Test Replay: unsupported content type'
    safelyApplyAttribute(createdNode, 'title', msg, isChildOfSvg)
  }
}

export const createNode = ({
  eventId,
  doc,
  node,
  networkResources,
  nodeMap,
  hrefResolver,
  parentNodeElementType,
  isChildOfSvg,
  config,
  nodePatches = {},
  base64AssetMetadata,
  hasCapturedAndProcessedCanvasAssets,
}: {
  eventId: number
  doc: Document
  node: DBNodePayload
  networkResources: NetworkResources
  nodeMap: NodeMap
  hrefResolver: HrefResolver
  parentNodeElementType?: string
  isChildOfSvg?: boolean
  nodePatches?: {
    [key: string]: Partial<Node>
  }
  config?: {
    includeShadowDom?: boolean
    skipSafeAttrMap?: boolean
  }
  base64AssetMetadata?: Base64AssetMetadata
  hasCapturedAndProcessedCanvasAssets: boolean
}) => {
  // todo: figure out correct type for this
  let createdNode = null as any

  // Apply node patches
  if (node.attributes && node.attributes['data-cy-node-patch']) {
    merge(node, nodePatches[node.attributes['data-cy-node-patch']] || {})
    delete node.attributes['data-cy-node-patch']
  }

  switch (node.nodeType) {
    case Node.ELEMENT_NODE: {
      if (
        node.nodeName === 'IFRAME' &&
        node.frameId &&
        nodeMap.pendingIFrameMap.get(node.frameId)
      ) {
        const frameId = node.frameId
        const { iframe, contentDocumentNode } =
          nodeMap.pendingIFrameMap.get(frameId)!
        createdNode = safelyCreateElement(doc, node)

        // The iframe window will not be created until the iframe is added to the window
        // So we create a callback to replace the created node contents with the pending iframe contents
        // once it is added
        nodeMap.postAddingToWindowCallbacks.push(() => {
          const replaceIframeContents = () => {
            const currentContentDocument = createdNode.contentDocument
            // If we can't find the currentContentDocument or the documentElement, we can't replace the iframe yet
            // so we return false to keep the callback in the queue
            if (
              !currentContentDocument?.documentElement ||
              !iframe.contentDocument?.documentElement ||
              !nodeMap.getId(iframe.contentDocument?.documentElement)
            ) {
              return false
            }

            nodeMap.applyPendingAdoptedStylesheets()
            nodeMap.applyPendingElementStylesheets()

            currentContentDocument.replaceChild(
              currentContentDocument.adoptNode(
                iframe.contentDocument.documentElement
              ),
              currentContentDocument.documentElement
            )

            nodeMap.pendingIFrameMap.delete(frameId)
            iframe.parentNode?.removeChild(iframe)

            safelyApplyAttribute(
              createdNode,
              'data-cy-replay-frame-id',
              frameId
            )
            nodeMap.addFrame(
              createdNode,
              frameId,
              hasCapturedAndProcessedCanvasAssets
            )

            hrefResolver.setCurrentLocation(
              node.frameId!,
              contentDocumentNode.documentURL!
            )
            nodeMap.add(createdNode, contentDocumentNode, true)

            return true
          }

          // Even though the iframe is added to the window, the pending iframe may not have
          // a documentElement yet. So we need to wait for it to be added before we can replace the iframe
          if (!iframe.contentDocument!.documentElement) {
            nodeMap.documentElementCallbackMap.set(
              frameId,
              replaceIframeContents
            )
          } else {
            replaceIframeContents()
          }
        })
      } else if (node.nodeName === 'SCRIPT' || node.nodeName === 'NOSCRIPT') {
        node.nodeName = 'NOSCRIPT'
        node.localName = 'noscript'
        createdNode = safelyCreateElement(doc, node)
        createdNode.style.display = 'none'
      } else if (
        node.nodeName.toUpperCase() === 'CANVAS' &&
        hasCapturedAndProcessedCanvasAssets
      ) {
        let base64Image
        if (node.attributes?.['data-cy-discovery-canvas-image']) {
          base64Image = node.attributes['data-cy-discovery-canvas-image']
          delete node.attributes['data-cy-discovery-canvas-image']
        }

        createdNode = safelyCreateElement(doc, node)

        if (base64Image) {
          const array = Uint8Array.from(atob(base64Image), (c) =>
            c.charCodeAt(0)
          )
          const blob = new Blob([array])
          createImageBitmap(blob)
            .then((bitmap) => {
              const ctx = (createdNode as HTMLCanvasElement).getContext(
                'bitmaprenderer'
              )
              ctx?.transferFromImageBitmap(bitmap)
            })
            .catch((err) => {
              console.error('Failed to create image bitmap', err)
            })
        }
      } else if (
        node.nodeName.toUpperCase() === 'VIDEO' ||
        (node.nodeName.toUpperCase() === 'CANVAS' &&
          !hasCapturedAndProcessedCanvasAssets)
      ) {
        createdNode = safelyCreateElement(doc, node)

        safelyApplyAttribute(
          createdNode,
          'data-cy-replay-is-placeholder',
          '',
          isChildOfSvg
        )

        createdNode.style.width = node.attributes?.width
          ? `${node.attributes?.width}px`
          : ''
        createdNode.style.height = node.attributes?.width
          ? `${node.attributes?.height}px`
          : ''

        if (node.nodeName.toUpperCase() === 'VIDEO') {
          safelyApplyAttribute(
            createdNode,
            'poster',
            smallTransparentImage,
            isChildOfSvg
          )
        }
      } else if (['OBJECT'].includes(node.nodeName.toUpperCase())) {
        createdNode = safelyCreateElement(doc, node)

        createdNode.style.width = node.attributes?.width
          ? `${node.attributes?.width}px`
          : ''
        createdNode.style.height = node.attributes?.width
          ? `${node.attributes?.height}px`
          : ''
      } else if (['LINK', 'STYLE'].includes(node.nodeName.toUpperCase())) {
        let assetFromLink = ''

        const href = ensureHrefLikeUrl(node.attributes?.href)
        const hasChildren =
          node.children?.length && node.nodeName.toUpperCase() === 'STYLE'
        let possibleHash: string | undefined = ''
        if (href && !hasChildren) {
          const resolvedUrl = hrefResolver.resolve(href, doc)

          possibleHash =
            networkResources.domNetworkAssets.pathHash?.[resolvedUrl] ||
            networkResources.domNetworkAssets.pathHash?.[href] ||
            networkResources.domNetworkAssets.pathHash?.[`/${href}`]
          if (possibleHash) {
            const data =
              networkResources.domNetworkAssets.hashData[possibleHash]?.body
            if (!data) {
              warnOnce(
                possibleHash,
                `Failed to find response for (${possibleHash})`
              )
            }
            assetFromLink = data ?? ''
          } else {
            assetFromLink = href
          }
        }

        // If the assetFromLink is different from the current href, it means that we
        // are able to find data
        if (
          href &&
          (href.includes('.css') || href !== assetFromLink) &&
          // [CYCLOUD-1649] We ignore preload Links since the data will be loaded elsewhere
          node.attributes?.rel !== 'preload' &&
          !hasChildren
        ) {
          assetFromLink = rewriteCss(
            doc,
            networkResources,
            assetFromLink,
            hrefResolver,
            href,
            possibleHash,
            false,
            base64AssetMetadata
          )
          createdNode = doc.createElement('style')
          createdNode.appendChild(doc.createTextNode(assetFromLink))
        } else {
          createdNode = safelyCreateElement(doc, node)
        }
      } else if (node.nodeName.toUpperCase() === 'SOURCE') {
        createdNode = safelyCreateElement(doc, node)

        if (node.attributes?.srcset) {
          const urlsArray = node.attributes.srcset.split(',').map((url) => {
            const [_url] = url.trim().split(' ')
            return _url
          })

          urlsArray.forEach((url) => {
            if (url) {
              const possibleHash =
                networkResources.domNetworkAssets.pathHash?.[url]

              if (possibleHash && node.attributes) {
                const base64Asset = toBase64Asset(
                  networkResources.domNetworkAssets.hashData[possibleHash]
                )
                if (base64AssetMetadata) {
                  updateMetadataWithBase64Asset(
                    base64AssetMetadata,
                    base64Asset
                  )
                }
                node.attributes.srcset = base64Asset
              }
            }
          })
        }
      } else if (node.nodeName.toUpperCase() === 'IMG') {
        createdNode = safelyCreateElement(doc, node)
        if (node.attributes?.src) {
          const possibleHash =
            networkResources.domNetworkAssets.pathHash?.[node.attributes.src] ||
            networkResources.domNetworkAssets.pathHash?.[
              hrefResolver.resolve(node.attributes.src, doc)
            ]

          if (possibleHash) {
            const base64Asset = toBase64Asset(
              networkResources.domNetworkAssets.hashData[possibleHash]
            )
            if (base64AssetMetadata) {
              updateMetadataWithBase64Asset(base64AssetMetadata, base64Asset)
            }
            node.attributes.src = base64Asset
          }
        }

        if (node.attributes?.srcset) {
          const srcSetEntries = node.attributes.srcset.split(',').map((src) => {
            // each segment in the comma-separated set is a
            // space-separated "{imgUrl} {width}" tuple
            return src.trim().split(' ')
          })

          let base64SrcSet = ''

          srcSetEntries.forEach(([url, width]) => {
            if (url) {
              const possibleHash =
                networkResources.domNetworkAssets.pathHash?.[url]

              if (possibleHash) {
                if (base64SrcSet) {
                  base64SrcSet += ', '
                }

                const base64Asset = toBase64Asset(
                  networkResources.domNetworkAssets.hashData[possibleHash]
                )
                if (base64AssetMetadata) {
                  updateMetadataWithBase64Asset(
                    base64AssetMetadata,
                    base64Asset
                  )
                }

                base64SrcSet += base64Asset

                // width may not always be present and is not specified
                // for the final (default) segment of the set
                if (width) {
                  base64SrcSet += ` ${width}`
                }
              }
            }
          })

          if (base64SrcSet) {
            node.attributes.srcset = base64SrcSet
          }
        }
      } else {
        // The xmlns attribute is only required on the outermost svg element
        // https://developer.mozilla.org/en-US/docs/Web/SVG/Element/svg
        // isSVG returns true for any svg element however
        if (node.isSVG && node.localName.toUpperCase() === 'SVG') {
          createdNode = doc.createElementNS(
            'http://www.w3.org/2000/svg',
            node.localName
          )
        } else {
          createdNode = safelyCreateElement(doc, node)
          if (
            // if id is 'localName', this could error without this check
            // https://github.com/cypress-io/cypress-services/issues/9188
            typeof node.localName.toUpperCase === 'function' &&
            node.localName.toUpperCase() === 'BASE' &&
            node.attributes?.href
          ) {
            const resolvedBaseHref =
              hrefResolver.resolve(node.attributes.href, doc) ?? ''
            hrefResolver.setCurrentLocation(
              hrefResolver.getDocumentFrameId(doc),
              resolvedBaseHref
            )
            node.attributes.href = resolvedBaseHref
          }
        }
      }

      // For this case we only want to change the attribute on the node but
      // avoid modifying the createdNode - it can be part of a svg, so, if we
      // handle it independently it may not render correctly the image.
      if (['IMAGE', 'USE'].includes(node.nodeName.toUpperCase())) {
        if (node.attributes?.['xlink:href']) {
          const xlinkHref = node.attributes['xlink:href']

          const resolvedUrl = hrefResolver.resolve(xlinkHref, doc)
          const possibleHash =
            networkResources.domNetworkAssets.pathHash?.[resolvedUrl] ||
            networkResources.domNetworkAssets.pathHash?.[xlinkHref] ||
            networkResources.domNetworkAssets.pathHash?.[`/${xlinkHref}`]

          if (possibleHash) {
            const asset =
              networkResources.domNetworkAssets.hashData[possibleHash]

            if (asset?.mimeType?.includes('svg')) {
              createdNode.innerHTML = asset.body
              safelyApplyAttribute(createdNode, 'xlink:href', xlinkHref, true)

              const safelyAppliedAttribute =
                createdNode.getAttribute('xlink:href')
              // If we're dealing with a reference to a specific id, only use the contents of that
              // id rather than the full svg.
              if (
                safelyAppliedAttribute?.includes('#') &&
                safelyAppliedAttribute !== '#'
              ) {
                try {
                  const actualSvg = createdNode.querySelector(
                    safelyAppliedAttribute
                  )
                  if (actualSvg) {
                    createdNode.innerHTML = actualSvg.outerHTML
                  }
                } catch (error) {
                  // if we cannot find the element, we will just use the full svg
                }
              }
            } else {
              const base64Asset = toBase64Asset(
                networkResources.domNetworkAssets.hashData[possibleHash]
              )

              if (base64AssetMetadata) {
                updateMetadataWithBase64Asset(base64AssetMetadata, base64Asset)
              }

              node.attributes['xlink:href'] = base64Asset
            }
          }
        }
      }

      if (node.propertyState) {
        // used to hydrate initial values of <input>, <select>, <textarea>, and other elements
        for (const [key, value] of Object.entries(node.propertyState || {})) {
          // In the case of multi-select, the order in which attributes/values are hydrated matters
          // for the case of multiselect, we need to set createdNode.multiple = true before cloning and hydrating
          // the options as children inside the <select> element.
          // Otherwise, only the last option to set selected=true will be selected!
          safelyApplyProperty(createdNode, key, value)
        }
      }

      for (const child of node.children || []) {
        const childNode = createNode({
          eventId,
          doc,
          node: child,
          networkResources,
          nodeMap,
          hrefResolver,
          parentNodeElementType: createdNode.localName,
          isChildOfSvg: node.isSVG,
          config,
          nodePatches,
          base64AssetMetadata,
          hasCapturedAndProcessedCanvasAssets,
        })
        if (childNode) {
          createdNode.appendChild(childNode)
        }
      }

      if (config?.includeShadowDom && node.shadowRoots) {
        for (const shadowRoot of node.shadowRoots) {
          if (shadowRoot.shadowRootType !== 'user-agent') {
            const shadow = (createdNode as Element).attachShadow({
              mode: shadowRoot.shadowRootType as ShadowRootMode,
            })

            for (const child of shadowRoot.children) {
              const childNode = createNode({
                eventId,
                doc,
                node: child,
                networkResources,
                nodeMap,
                hrefResolver,
                parentNodeElementType: createdNode.localName,
                isChildOfSvg: node.isSVG,
                config,
                nodePatches,
                base64AssetMetadata,
                hasCapturedAndProcessedCanvasAssets,
              })
              if (childNode) {
                shadow.appendChild(childNode)
              }
            }

            nodeMap.add(shadow, shadowRoot)
          }
        }
      }

      for (const [attr, value] of Object.entries(node.attributes || {})) {
        let formattedValue = value
        if (attr.toUpperCase() === 'STYLE') {
          let styleContent = value
          // Merge created styles (bg image) with the existing styles
          if (
            ['VIDEO', 'NOSCRIPT'].includes(node.nodeName) ||
            (node.nodeName === 'CANVAS' && !hasCapturedAndProcessedCanvasAssets)
          ) {
            styleContent = `${value}; ${createdNode.style.cssText}`
          }

          formattedValue = rewriteCss(
            doc,
            networkResources,
            styleContent,
            hrefResolver,
            '',
            undefined,
            true,
            base64AssetMetadata
          )
        }

        safelyApplyAttribute(createdNode, attr, formattedValue, isChildOfSvg)
      }

      if (typeof window !== 'undefined' && window['IN_DISCOVERY']) {
        safelyApplyAttribute(
          createdNode,
          'data-cy-replay-node-id',
          node.id.toString()
        )
      }

      break
    }
    case Node.TEXT_NODE: {
      if (parentNodeElementType?.toUpperCase() === 'STYLE') {
        createdNode = doc.createTextNode(
          rewriteCss(
            doc,
            networkResources,
            node.nodeValue,
            hrefResolver,
            '',
            // Use contentHash as the key if it exists in the case that this was sourced from a nodePatch
            // and we can't trust the node.id passed in to be correct.
            `${eventId}-${node.contentHash || node.id}`,
            false,
            base64AssetMetadata
          )
        )
      } else if (parentNodeElementType?.toUpperCase() === 'NOSCRIPT') {
        createdNode = doc.createComment('script removed by Cypress')
      } else {
        createdNode = doc.createTextNode(node.nodeValue)
      }

      break
    }
    case Node.COMMENT_NODE: {
      createdNode = doc.createComment(node.nodeValue)
      break
    }
    case Node.DOCUMENT_TYPE_NODE: {
      createdNode = doc.implementation.createDocumentType(
        !isEmpty(node.nodeName) ? node.nodeName : 'html',
        node.publicId!,
        node.systemId!
      )
      break
    }
    case Node.DOCUMENT_NODE: {
      addDocType(doc)
      doc.close()
      doc.open()

      if (node.documentURL) {
        hrefResolver.setCurrentLocation(
          hrefResolver.getDocumentFrameId(doc)!,
          node.documentURL,
          { isNavigationEvent: true }
        )
      }

      if (node.children) {
        // TODO: using the iframe directly...this doesn't handle child iframes
        for (const child of node.children) {
          const childNode = createNode({
            eventId,
            doc,
            node: child,
            networkResources,
            nodeMap,
            hrefResolver,
            config,
            nodePatches,
            base64AssetMetadata,
            hasCapturedAndProcessedCanvasAssets,
          })
          if (childNode) {
            doc.appendChild(childNode)

            if (childNode.nodeType === Node.ELEMENT_NODE) {
              const callback = nodeMap.documentElementCallbackMap.get(
                node.frameId!
              )
              if (callback) {
                callback()
                nodeMap.documentElementCallbackMap.delete(node.frameId!)
              }
            }
          }
        }
      }

      createdNode = doc
      break
    }
    default: {
      // https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
      // See node types here for mappings
      warnOnce(node.id.toString(), 'Unmapped Node Type: ', node)
      break
    }
  }

  // Only add the frameId to the node map if it is not a pending iframe.
  // If it's a pending iframe, we will add the frameId once the iframe is added to the window
  if (node.frameId && !nodeMap.pendingIFrameMap.get(node.frameId!)) {
    safelyApplyAttribute(createdNode, 'data-cy-replay-frame-id', node.frameId)
    nodeMap.addFrame(
      createdNode,
      node.frameId,
      hasCapturedAndProcessedCanvasAssets
    )
  }

  if (node.contentDocument) {
    hrefResolver.setCurrentLocation(
      node.frameId!,
      node.contentDocument.documentURL!
    )
    nodeMap.add(createdNode, node.contentDocument, true)
    if (
      !nodeMap.pendingIFrameMap.get(node.frameId!) &&
      node.contentDocument.children.length > 0
    ) {
      nodeMap.postAddingToWindowCallbacks.push(() => {
        if (createdNode.contentDocument) {
          createNode({
            eventId,
            doc: createdNode.contentDocument,
            node: node.contentDocument!,
            networkResources,
            nodeMap,
            hrefResolver,
            parentNodeElementType: createdNode.localName,
            isChildOfSvg,
            config,
            nodePatches,
            base64AssetMetadata,
            hasCapturedAndProcessedCanvasAssets,
          })
        }
      })
    }
  }

  applyTitleToMaskedNodes(
    createdNode,
    isChildOfSvg,
    hasCapturedAndProcessedCanvasAssets
  )

  nodeMap.add(createdNode, node)

  return createdNode
}
