import React from 'react'
import './RunDurationTooltip.scss'
import { IconShapeLightningBolt } from '@cypress-design/react-icon'
import { Chip } from '@frontend/design-system'
import cs from 'clsx'
import { ChipVariant } from '@frontend/design-system/src/components/Chip'

export type ConfigurationColor = 'red' | 'jade' | 'gray'

export const ConfigurationRow: React.FC<{
  label?: string
  value?: string
  color?: ConfigurationColor
  valueOverride?: React.ReactNode
  hideIcon?: boolean
  'data-cy'?: string
  group?: boolean
  icon?: React.ReactNode
  chipVariant?: ChipVariant
}> = ({
  label,
  value,
  color,
  valueOverride,
  hideIcon = false,
  group = false,
  chipVariant,
  icon = <IconShapeLightningBolt strokeColor="gray-500" />,
  ...rest
}) => {
  const baseValueDisplay = (value || chipVariant) && (
    <Chip color={color} value={value} variant={chipVariant} />
  )
  const finalValueDisplay = valueOverride || baseValueDisplay || null

  return (
    <div className="config-row" data-cy={rest['data-cy']}>
      <div className={cs({ 'config-group': group }, 'feat-label')}>
        {!hideIcon && icon}
        <p className={cs({ 'group-border': group })}>{label}</p>
      </div>
      {finalValueDisplay}
    </div>
  )
}
