export default {
  get(key) {
    try {
      return JSON.parse(localStorage[key])
    } catch (e) {
      return null
    }
  },

  set(key, value) {
    return (localStorage[key] = JSON.stringify(value))
  },
}
