import {
  RunGroupStatusEnum,
  RunOverviewMetricsQuery,
  GroupNameNode,
  Maybe,
} from '~/graphql-codegen-operations.gen'
import Spec from '~/specs/spec-model'
import groupsStore from '~/groups/groups-store'
import machinesStore from '~/machines/machines-store'
import { Recommendation } from '~/specs/parallelization-recommendation/recommendation'
import { filter } from 'lodash'
import { features } from '~/lib/feature-flags'

type RunWithOverview = RunOverviewMetricsQuery['run']

type PastRuns = NonNullable<
  RunOverviewMetricsQuery['run']['overview']
>['pastRuns']

type ColumnItem =
  | {
      type: 'BRANCH_CHANGE'
      data: { name: string }
    }
  | {
      type: 'CONFIGURATION_CHANGES'
      data: ConfigurationChanges
    }
  | {
      type: 'RUN_DURATION'
      data: RunDetails
    }

export type RunDetails = NonNullable<PastRuns[number]> & {
  diffSpecs: number
  diffTests: number
}

// "PastRuns" do not have RawRecommendations data included in
// the payload for network performance reasons. We merge that
// data ad hoc to create this entity when required.
export type RunWithRawRecommendationsData = RunDetails & {
  machineCount: Maybe<number>
  totalDuration: Maybe<number>
  specIsolation: Maybe<boolean>
  instances: Maybe<RunWithOverview['instances']>
}

export type AnalyzedRun = NonNullable<ReturnType<typeof analyzeRunOverview>>

export type SavingsRecommendations = {
  projectedAutoCancelSavings: string
  projectedAutoCancelWithSpecPrioritizationSavings: string
  projectedOnlySpecPrioritizationSavings: string
}

type UniqueGroupChanges = {
  type: 'ADDED' | 'CHANGED' | 'REMOVED'
  prev?: GroupNameNode
  next?: GroupNameNode
}

export type ConfigChangeBasic =
  | {
      configKey: 'buildNumber' | 'uniqueGroupsCount'
      prev: number
      next: number
    }
  | {
      configKey: 'cypressVersion'
      prev: string
      next: string
    }
  | {
      configKey:
        | 'settingCancelOnFailure'
        | 'settingPrioritizedByFailedSpecs'
        | 'flakeDetection'
      prev: boolean
      next: boolean
    }

export type ConfigChangeUniqueGroups = {
  configKey: 'uniqueGroups'
  added: UniqueGroupChanges[]
  changed: UniqueGroupChanges[]
  removed: UniqueGroupChanges[]
}

export type ConfigurationChanges = Array<
  ConfigChangeBasic | ConfigChangeUniqueGroups
> | null

type RunConfigDetails = Pick<
  NonNullable<PastRuns[number]>,
  | 'buildNumber'
  | 'cypressVersion'
  | 'settingCancelOnFailure'
  | 'settingPrioritizedByFailedSpecs'
  | 'uniqueGroups'
  | 'uniqueGroupsCount'
  | 'status'
  | 'project'
>

export const UNIQUE_GROUPS_LIMIT: number = 30 // # of uniqueGroups to pull from database. Capped for performance reasons

/**
 * Config Changes info can change over time as a Run
 * executes and completes. To avoid customer confusion,
 * we only display "Parallelization" and "Groups" info once
 * the Run has reached a terminal state.
 */
function getDisplayedChanges(
  prevRun: RunConfigDetails,
  nextRun: RunConfigDetails,
  allChanges: NonNullable<ConfigurationChanges>
) {
  // configs that can change while the Run is being executed:
  const unstableConfigs = ['uniqueGroups', 'uniqueGroupsCount']
  const hasMainChanges = (arr: typeof allChanges) =>
    arr.some((c) => c.configKey !== 'buildNumber')

  if (!hasMainChanges(allChanges)) {
    return null
  }

  if (prevRun.status === 'RUNNING' || nextRun.status === 'RUNNING') {
    const stableConfigs = allChanges?.filter((c) => {
      return !unstableConfigs.includes(c.configKey)
    })
    return hasMainChanges(stableConfigs) ? stableConfigs : null
  }

  return allChanges
}

export const getConfigurationChanges = (
  prevRun: RunConfigDetails,
  nextRun: RunConfigDetails
): ConfigurationChanges => {
  const configKeys = [
    'buildNumber',
    'cypressVersion',
    'settingCancelOnFailure',
    'settingPrioritizedByFailedSpecs',
    'uniqueGroups',
    'uniqueGroupsCount',
  ]
  const isBurnInEnabled = features.isEnabled('test-burn-in', {
    projectId: prevRun.project.id || nextRun.project.id,
  })

  if (isBurnInEnabled) {
    configKeys.push('burnInConfig')
  }

  const shouldDiffUniqueGroups: Boolean =
    prevRun.uniqueGroupsCount <= UNIQUE_GROUPS_LIMIT &&
    nextRun.uniqueGroupsCount <= UNIQUE_GROUPS_LIMIT

  const changedValues = configKeys
    .map((configKey) => {
      const prevValue = prevRun[configKey]
      const nextValue = nextRun[configKey]

      if (configKey === 'uniqueGroupsCount' && shouldDiffUniqueGroups) {
        return null
      }

      if (configKey === 'uniqueGroups') {
        if (!shouldDiffUniqueGroups) {
          return null
        }

        const uniqueGroups = {
          added: [] as UniqueGroupChanges[],
          changed: [] as UniqueGroupChanges[],
          removed: [] as UniqueGroupChanges[],
        }

        uniqueGroups.added = nextValue
          .filter(
            ({ name, status }) =>
              status !== 'UNCLAIMED' &&
              !prevValue.some((prev) => name === prev.name)
          )
          .map((next) => ({ next, type: 'ADDED' }))

        prevValue.forEach((prev) => {
          if (prev.status !== 'UNCLAIMED') {
            const next = nextValue.find(({ name }) => name === prev.name)
            if (!next) {
              uniqueGroups.removed.push({ prev, type: 'REMOVED' })
            } else if (prev.machineCount !== next.machineCount) {
              uniqueGroups.changed.push({ prev, next, type: 'CHANGED' })
            }
          }
        })

        const didChange = Boolean(
          [
            ...uniqueGroups.added,
            ...uniqueGroups.changed,
            ...uniqueGroups.removed,
          ].length
        )

        return didChange ? { configKey, ...uniqueGroups } : null
      }

      if (configKey === 'burnInConfig') {
        return prevValue?.isEnabled === nextValue?.isEnabled
          ? null
          : {
              configKey,
              prev: prevValue?.isEnabled,
              next: nextValue?.isEnabled,
            }
      }

      return prevValue === nextValue
        ? null
        : { configKey, prev: prevValue, next: nextValue }
    })
    .filter(Boolean) as NonNullable<ConfigurationChanges>

  return getDisplayedChanges(prevRun, nextRun, changedValues)
}

function makeColumnItems(run: RunWithOverview) {
  const invalidOrder = [...(run.overview?.pastRuns ?? [])]
  const orderedPastRuns = invalidOrder.reverse().filter(Boolean)
  const items: ColumnItem[] = []

  orderedPastRuns.forEach((thisRun, idx, list) => {
    // last item in the list treats
    // the main run as the "next" run:
    const nextRun = list[idx + 1] ?? run

    const prevRun = list[idx - 1] ?? {
      instances: { totalCount: 0 },
      totalTests: 0,
    }

    items.push({
      type: 'RUN_DURATION',
      data: {
        ...thisRun!,
        diffTests: (thisRun?.totalTests ?? 0) - (prevRun?.totalTests ?? 0),
        diffSpecs:
          (thisRun?.instances.totalCount ?? 0) -
          (prevRun?.instances?.totalCount ?? 0),
      },
    })

    const configChanges = getConfigurationChanges(thisRun!, nextRun)
    if (configChanges) {
      items.push({
        type: 'CONFIGURATION_CHANGES',
        data: configChanges,
      })
    }

    const nextNodeIsNewBranch = thisRun?.commit.branch !== nextRun.commit.branch
    if (nextNodeIsNewBranch) {
      items.push({
        type: 'BRANCH_CHANGE',
        data: { name: nextRun.commit.branch! },
      })
    }
  })

  return items
}

// parses the backend response and includes
// details we need to render components downstream:
export function analyzeRunOverview(run?: RunWithOverview) {
  if (!run) {
    return null
  }

  // if there is no latestFromCurrentBranch it means there are no other runs matching the current filters
  // so our current run would be our 'most recent run'
  const isMostRecent =
    run.latestFromCurrentBranch === null ||
    run?.buildNumber === run.latestFromCurrentBranch?.buildNumber

  // Compare oldest overview run branch with main run branch,
  // if they are the same, then all runs are from the same branch.
  const isRunsFromSingleBranch =
    run.commit.branch ===
    run.overview?.pastRuns[run.overview?.pastRuns.length - 1]?.commit.branch

  const columnItems = makeColumnItems(run)

  const getDuration = (
    runItem?: Maybe<{
      runningDuration: Maybe<number>
      totalDuration: Maybe<number>
    }>
  ) => runItem?.totalDuration ?? runItem?.runningDuration

  const durationList = [
    getDuration(run),
    ...(run?.overview?.pastRuns?.map((r) => getDuration(r)) ?? []),
  ].filter(Boolean) as number[]

  const longestRunTime = Math.max(...durationList)

  const latestPastRun = run.overview?.pastRuns[0]

  return {
    run: {
      ...run,
      diffTests: run.totalTests - (latestPastRun?.totalTests ?? 0),
      diffSpecs:
        run.instances.totalCount - (latestPastRun?.instances.totalCount ?? 0),
    },
    isMostRecent,
    longestRunTime,
    isRunsFromSingleBranch,
    columnItems,
  } as const
}

export function getParallelizationRecommendations(
  run: RunWithRawRecommendationsData
): {
  name: string
  recommendation: Recommendation
  status: RunGroupStatusEnum
}[] {
  /*
    NOTE: 

    Recommendation logic was written 4yrs ago. It was never updated 
    to use our new db tables. To remain consistent with previous features, 
    we use the same old approach for generating the recommendation calculations.
  */
  const specModels = run.instances?.nodes.map((i) => new Spec(i)) ?? []
  const groupModels = groupsStore.getGroups(specModels)

  return groupModels.map((g) => {
    const machines = machinesStore.getMachines(g.specs)
    const machinesCount = filter(machines, { groupId: g.id }).length
    const recommendation = new Recommendation(
      run,
      machinesCount,
      g.specs,
      g.groupDuration
    )
    return {
      name: g.name,
      recommendation,
      status: g.status as RunGroupStatusEnum,
    }
  })
}

export function getParallelizationPercents(run: RunWithRawRecommendationsData) {
  return getParallelizationRecommendations(run).map(
    ({ name, recommendation, status }) => {
      // parallelization not applicable if percent=null:
      const notApplicable = {
        groupName: name,
        groupStatus: status,
        percent: null,
      }

      if (
        !recommendation.canBeParallelized ||
        !recommendation.isReady ||
        !recommendation.current
      ) {
        return notApplicable
      }

      // Jira ticket requests we calculate this way:
      // https://cypress-io.atlassian.net/browse/CLOUD-794

      const leastIdeal = recommendation.machineRows?.[0].duration
      const mostIdeal = recommendation.fastest.duration
      const current = recommendation.current.duration

      const numerator = current - mostIdeal
      const denominator = leastIdeal - mostIdeal

      let calculation = 1
      if (denominator !== 0) {
        calculation -= numerator / denominator
      }

      return {
        groupName: name,
        groupStatus: status,
        percent: Math.floor(calculation * 100),
      }
    }
  )
}

export function getParallelizationCandidates(analysis: AnalyzedRun) {
  if (!analysis) {
    return {}
  }

  const isRunning = analysis.run.status === 'RUNNING'
  const recommendations = getParallelizationRecommendations(analysis.run)
  const groupsWithPotentialSavings = recommendations.filter(
    ({ recommendation }) => {
      const canBeParallelized = recommendation.canBeParallelized
      const hasTimeSavings = (recommendation.potentialSaveTime ?? 0) > 0
      return isRunning ? canBeParallelized : canBeParallelized && hasTimeSavings
    }
  )
  return {
    recommendations,
    groupsWithPotentialSavings,
  }
}

export const getTimeSavingDescription = (timeSaving: string | undefined) => {
  return !timeSaving || timeSaving === 'Not enough data'
    ? ''
    : `by ${timeSaving} / 100 runs`
}

export const getCurrentProjectedSavings = (
  isCancelOnFailureChecked: boolean,
  isFailedSpecPrioritizedChecked: boolean,
  savings: Maybe<SavingsRecommendations>
) => {
  if (isCancelOnFailureChecked) {
    if (isFailedSpecPrioritizedChecked) {
      return savings?.projectedAutoCancelWithSpecPrioritizationSavings
    }
    return savings?.projectedAutoCancelSavings
  }
  return '0m'
}
