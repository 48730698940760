import {
  AccessibilityResults,
  UiCoverageResults,
} from '~/graphql-codegen-operations.gen'
import { IntelligenceVariant } from './types'

type ResultVariantData = {
  accessibilityResults?: Partial<AccessibilityResults> | null
  uiCoverageResults?: Partial<UiCoverageResults> | null
  variant: IntelligenceVariant
}

/**
 * Simple switch to return the correct results based on the variant.
 * @param run
 * @param variant
 * @returns
 */
export const getVariantResults = ({
  accessibilityResults,
  uiCoverageResults,
  variant,
}: ResultVariantData) => {
  if (variant === 'accessibility') {
    return accessibilityResults
  }

  if (variant === 'ui-coverage') {
    return uiCoverageResults
  }

  return null
}

export const isPendingDiscoveryState = (
  resultVariantData: ResultVariantData
) => {
  const status = getVariantResults(resultVariantData)?.status
  return status === 'PROCESSING'
}

/**
 * A convenience method to return true if either premium product is pending.
 * @param runData a subset of run data
 * @returns
 */
export const isEitherIntelligenceProductPendingDiscoveryState = (runData: {
  accessibilityResults?: Partial<AccessibilityResults> | null
  uiCoverageResults?: Partial<UiCoverageResults> | null
}) => {
  return (
    isPendingDiscoveryState({ ...runData, variant: 'accessibility' }) ||
    isPendingDiscoveryState({ ...runData, variant: 'ui-coverage' })
  )
}

export const isErroredDiscoveryState = (resultVariantData: ResultVariantData) =>
  getVariantResults(resultVariantData)?.status === 'PROCESSING_FAILED'

export const isFetchableDiscoveryState = (
  resultVariantData: ResultVariantData
) => {
  const results = getVariantResults(resultVariantData)
  return (
    results &&
    (results.status === 'PROCESSED' ||
      results.status === 'PROCESSED_WITH_ERRORS')
  )
}

export const intelligenceVariants: IntelligenceVariant[] = [
  'ui-coverage',
  'accessibility',
]
