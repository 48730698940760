import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useLayoutEffect,
} from 'react'
import { uniqueId } from 'lodash'

export const HIDE_BUTTON_CLASS = 'pendo-context_hide-button'

export type PendoContextType = {
  hideResourceCenterButton(id: string): void
  showResourceCenterButton(id: string): void
}

export const PendoContext = createContext<PendoContextType | null>(null)

const setButtonIsHidden = (isHidden) => {
  const htmlClassList = document.querySelector('html')?.classList

  if (htmlClassList) {
    isHidden
      ? htmlClassList.add(HIDE_BUTTON_CLASS)
      : htmlClassList.remove(HIDE_BUTTON_CLASS)
  }
}

export const PendoProvider = ({ children }) => {
  const [hiddenButtonIds, setHiddenButtonIds] = useState<Set<string>>(
    new Set<string>()
  )

  useLayoutEffect(() => {
    setButtonIsHidden(hiddenButtonIds.size > 0)
  }, [hiddenButtonIds])

  useLayoutEffect(() => {
    return () => setButtonIsHidden(false)
  }, [])

  const contextValue = useMemo(
    () => ({
      hideResourceCenterButton: (id: string) => {
        setHiddenButtonIds((currentIds) => {
          const newSet = new Set(currentIds)
          newSet.add(id)
          return newSet
        })
      },
      showResourceCenterButton: (id: string) => {
        setHiddenButtonIds((currentIds) => {
          const newSet = new Set(currentIds)
          newSet.delete(id)
          return newSet
        })
      },
    }),
    []
  )

  return (
    <PendoContext.Provider value={contextValue}>
      {children}
    </PendoContext.Provider>
  )
}

// Hides the app's pendo button when mounted (and shows
// it again when unmounted)
type HidePendoButtonOpts = {
  // If true, hook will not activate and Pendo button will remain visible
  disabled?: boolean
}
export const useHidePendoButton = ({ disabled }: HidePendoButtonOpts = {}) => {
  const [hookId] = useState<string>(() => uniqueId('useHidePendoButton_'))
  const pendoContext = useContext(PendoContext)

  useLayoutEffect(() => {
    if (!pendoContext || disabled) {
      return undefined
    }

    pendoContext.hideResourceCenterButton(hookId)

    return () => {
      pendoContext.showResourceCenterButton(hookId)
    }
  }, [pendoContext, hookId, disabled])
}
