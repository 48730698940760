import { sortBy, filter, includes } from 'lodash'
import {
  RunContainerInstanceFragment,
  RunInstanceStatusEnum,
} from '~/graphql-codegen-operations.gen'

import Spec from './spec-model'

export type SpecsView = 'barChart' | 'timeline' | 'machines'

export const getSpecsByStatuses = <T = RunContainerInstanceFragment | Spec>(
  specs: T[],
  statuses: RunInstanceStatusEnum[]
): T[] => {
  return sortBy(
    filter(specs, (spec: RunContainerInstanceFragment | Spec) =>
      includes(statuses, spec.status)
    ),
    'wallClockStartedAt',
    'spec.path'
  ) as T[]
}

export const sortInstances = (instances: Spec[], specsView: SpecsView) => {
  if (specsView !== 'barChart') {
    return instances
  }

  return sortBy(instances, (spec: Spec) => {
    if (spec.status === 'RUNNING') {
      return -1
    }

    if (spec.status === 'UNCLAIMED') {
      return -2
    }

    if (spec.status === 'TIMEDOUT') {
      return -3
    }

    return spec.duration
  }).reverse() as Spec[]
}
