import findLast from 'lodash/findLast'

export function getLatestEvent<T>(
  events: any[],
  currTime: number,
  defaultValue?: T
): T {
  const lastEvent = findLast(
    events ?? [],
    (event: any) => event.timestamp <= currTime
  )?.payload
  return defaultValue && lastEvent === undefined ? defaultValue : lastEvent
}
