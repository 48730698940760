import React, { RefObject } from 'react'
import type {
  CanvasAssets,
  CypressAppEvent,
  ImagesAndStylesAssets,
} from '../webworkers/database.worker'
import type {
  EventsPayload,
  ElementToHighlight,
} from '@packages/app-capture-protocol/src/db/schemas/latest'
import type { ElementToHighlight as ElementToHighlightV9 } from '@packages/app-capture-protocol/src/db/schemas/v9'
import { usePreviousValueOf } from '@frontend/dashboard/src/lib/hooks/usePreviousValueOf'
import { HrefResolver } from './Replay/hrefResolver'
import { NodeMap } from './Replay/nodeMap'
import { useThrottle } from './useThrottle'
import { applyHighlightToElements } from './applyHighlightToElements'
import { ApplyEventContext, applyEventToReplay } from './applyEventToReplay'
import findLast from 'lodash/findLast'

export type DBNodePayload = EventsPayload['cdp']['dom:full-snapshot']['node']

export type NetworkResources = {
  domNetworkAssets: ImagesAndStylesAssets
}

export type CanvasResources = {
  domCanvasAssets?: CanvasAssets
}

const useIgnoreLeftClick = (iframeRef: RefObject<HTMLIFrameElement>) => {
  React.useEffect(() => {
    const doc =
      iframeRef.current?.contentDocument ??
      iframeRef.current?.contentWindow?.document
    function ignore(event: MouseEvent) {
      // only allow right-click so the user can inspect the dom in devtools,
      // and prevent regular clicks from interacting with app nested in AUT:
      if (event.button === 0) {
        event.preventDefault()
        event.stopPropagation()
      }
    }
    doc?.addEventListener('click', ignore, true)
    return () => doc?.removeEventListener('click', ignore, true)
  })
}

/**
 * when we clear the iframe and it's empty, we lose the doc type
 * causing the app to render in quirks mode in between clearing and rehydrating the dom
 * add the doc type when the iframe is empty to avoid quirks mode
 */
export const addDocType = (doc: Document | null | undefined) => {
  const docType = '<!DOCTYPE html>'
  doc?.write(docType)
}

export const useDomReplay = (args: {
  iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
  currentTime: number
  highlightElements: ElementToHighlight[] | ElementToHighlightV9[]
  actionHitbox: EventsPayload['cypress']['log:changed']['coords']
  currentlyPlaying: boolean
  attemptEvents: CypressAppEvent[]
  networkResources: NetworkResources
  canvasResources: CanvasResources
  signalForceRebuild?: boolean
}) => {
  const {
    iframeRef,
    currentlyPlaying,
    attemptEvents,
    networkResources,
    canvasResources,
    highlightElements,
    actionHitbox,
    signalForceRebuild,
  } = args
  // Limits aut rerenders to 10-20fps for better
  // responsiveness in the rest of the application:
  const prevValue = usePreviousValueOf(args.currentTime)
  // rewind is a more expensive operation than to go forward:
  const isRewind = (prevValue ?? 0) > (args.currentTime ?? 0)
  const currentTime = useThrottle(args.currentTime, isRewind ? 100 : 50)
  const fullSnapshots = React.useMemo(
    () => attemptEvents.filter((ev) => ev.type === 'dom:full-snapshot'),
    [attemptEvents]
  )

  const canvasSnapshotsByElementId = React.useMemo(() => {
    const canvasEvents = attemptEvents.filter(
      (ev) => ev.type === 'dom:canvas-snapshot'
    )
    const snapshotsByElementId = new Map<number, CypressAppEvent[]>()
    canvasEvents.forEach((snapshot) => {
      const elementId = snapshot.payload.elementId
      if (snapshotsByElementId.has(elementId)) {
        snapshotsByElementId.get(elementId)?.push(snapshot)
      } else {
        snapshotsByElementId.set(elementId, [snapshot])
      }
    })

    return snapshotsByElementId
  }, [attemptEvents])

  const snapshotUpdates = React.useMemo(
    () =>
      attemptEvents.filter((ev) =>
        [
          'dom:child-iframe-full-snapshot',
          'dom:aggregate-mutation',
          'dom:attribute-modified',
          'dom:attribute-removed',
          'dom:character-data-modified',
          'dom:node-added',
          'dom:node-removed',
          'dom:window-scrolled',
          'dom:element-scrolled',
          'dom:select-input-changed',
          'dom:text-input-changed',
          'dom:radio-input-changed',
          'dom:checkbox-input-changed',
          'dom:style-sheet-added',
          'dom:style-sheet-changed',
          'dom:style-sheet-removed',
          'dom:css-rule-inserted',
          'dom:css-rule-deleted',
          'dom:css-replaced',
          'dom:css-replaced-sync',
          'dom:css-adopted-style-sheets',
          'dom:css-style-declaration-property-set',
          'dom:css-style-declaration-property-removed',
          'dom:shadow-root-pushed',
          'dom:element-focused',
          'dom:element-blurred',
        ].includes(ev.type)
      ),
    [attemptEvents]
  )
  // NOTE: this var seems to be unused,
  // if so, should we remove it?
  const lastAppliedEventId = React.useRef<number>(-1)

  const lastAppliedSnapshotEvent = React.useRef<number>(-1)
  const lastAppliedTimestamp = React.useRef<number>(-1)
  const lastCurrentlyPlaying = React.useRef<boolean>(false)

  // The nodeMap must be rebuilt whenever replay is
  // rewound or when a new snapshot event is applied:
  const nodeMap = React.useRef<NodeMap>(new NodeMap())
  const hrefResolver = React.useRef<HrefResolver>(new HrefResolver())

  React.useEffect(() => {
    if (iframeRef.current === null) {
      return
    }
    // We want to build our displayed DOM from the last full snapshot.
    const mostRecentFullSnapshot = findLast(
      fullSnapshots,
      (snapshotEv: CypressAppEvent<any>) => {
        return snapshotEv.timestamp <= currentTime
      }
    )

    function cleanSlate() {
      lastAppliedSnapshotEvent.current = -1
      lastAppliedTimestamp.current = -1
      lastAppliedEventId.current = -1
      const doc = iframeRef.current?.contentDocument
      doc?.open()
      addDocType(doc)
      doc?.close()
    }

    if (!mostRecentFullSnapshot) {
      cleanSlate()
      return
    }
    const forceRebuild =
      signalForceRebuild ||
      lastAppliedTimestamp.current > currentTime ||
      mostRecentFullSnapshot?.id !== lastAppliedSnapshotEvent.current ||
      (lastCurrentlyPlaying.current === false && currentlyPlaying)

    lastCurrentlyPlaying.current = currentlyPlaying

    const applyEventCtx: ApplyEventContext = {
      iframeRef,
      nodeMap,
      networkResources,
      canvasResources,
      hrefResolver,
      config: {
        includeShadowDom: true,
      },
    }

    if (forceRebuild) {
      cleanSlate()
      applyEventToReplay(mostRecentFullSnapshot, applyEventCtx)
      lastAppliedSnapshotEvent.current = mostRecentFullSnapshot.id || -1
      lastAppliedTimestamp.current = mostRecentFullSnapshot.timestamp
    }

    // "scroll" mutations are very costly; we move
    // "scroll" events to the end of the sequence to
    // alleviate layout thrashing in between DOM changes:
    let lastUpdate: CypressAppEvent<any> | undefined
    const scrollsSinceSnapshot: typeof snapshotUpdates = []
    const withoutScrollEvents: typeof snapshotUpdates = []
    snapshotUpdates.forEach((update) => {
      const updateIsBeforeNextRenderTime = update.timestamp <= currentTime
      const updateIsAfterDomSnapshot =
        (mostRecentFullSnapshot?.timestamp || 0) <= update.timestamp
      const updateIsAfterLastRenderedTime =
        update.timestamp > lastAppliedTimestamp.current
      const updateIsOnTheSameTimeStampAsSnapshot =
        (update.id || 0) > (mostRecentFullSnapshot?.id || 0) &&
        update.timestamp === mostRecentFullSnapshot?.timestamp
      if (
        updateIsAfterDomSnapshot &&
        updateIsBeforeNextRenderTime &&
        (updateIsAfterLastRenderedTime || updateIsOnTheSameTimeStampAsSnapshot)
      ) {
        // track the true lastUpdate
        // from the persisted events:
        lastUpdate = update

        // "scroll" related events will get
        // pushed to the end of the sequence:
        if (update.type.includes('scroll')) {
          scrollsSinceSnapshot.push(update)
        } else {
          withoutScrollEvents.push(update)
        }
      }
    })
    lastAppliedTimestamp.current = currentTime

    const reshuffledMutationsSinceSnapshot =
      withoutScrollEvents.concat(scrollsSinceSnapshot)

    reshuffledMutationsSinceSnapshot.forEach((mutation) => {
      applyEventToReplay(mutation, applyEventCtx)
    })

    canvasSnapshotsByElementId.forEach((snapshots) => {
      // @ts-ignore - review ts configs for findLast support
      const canvasSnapshot = findLast(
        snapshots,
        (snapshotEv: CypressAppEvent<any>) =>
          snapshotEv.timestamp <= currentTime
      )
      if (canvasSnapshot) {
        applyEventToReplay(canvasSnapshot, applyEventCtx)
      }
    })

    nodeMap.current.applyPendingAdoptedStylesheets()
    nodeMap.current.applyPendingElementStylesheets()
    lastAppliedEventId.current = lastUpdate?.id || lastAppliedEventId.current

    applyHighlightToElements({
      highlightElementSelectors: highlightElements,
      actionHitbox,
      iframeRef,
      nodeMap: nodeMap.current,
    })
  }, [
    currentTime,
    currentlyPlaying,
    iframeRef,
    fullSnapshots,
    networkResources,
    canvasResources,
    canvasSnapshotsByElementId,
    snapshotUpdates,
    highlightElements,
    actionHitbox,
    signalForceRebuild,
  ])

  useIgnoreLeftClick(iframeRef)
}
