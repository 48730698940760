import { TestResultStatesValue } from '@packages/common/src/enums'
import { FlakyBadge, Tooltip } from '@frontend/design-system'
import StatusIcon from '@cypress-design/react-statusicon'
import type { StatusType } from '@cypress-design/react-statusicon'
import { Link } from '@reach/router'
import cs from 'clsx'
import { capitalize, toUpper } from 'lodash'
import queryString from 'query-string'
import React, { FunctionComponent } from 'react'
import styles from './module.RunStats.scss'
import { IconStatusFlaky } from '@cypress-design/react-icon'

type RunStatProps = {
  status: TestResultStatesValue
  value: number | null | undefined
  link: string | null
  showTooltip?: boolean
  projectsListV2?: boolean
}

const RunStat: FunctionComponent<RunStatProps> = ({
  status,
  value,
  link,
  showTooltip = true,
  projectsListV2,
}) => {
  const formattedStatus = status === 'cancelled' ? 'canceled' : status
  const label = link
    ? `View ${formattedStatus} tests`
    : `${capitalize(formattedStatus)} tests`

  const stat = link ? (
    <li data-cy={`total-${status}`} data-pendo={`total-${status}`}>
      <Link
        data-cy={`link-${status}`}
        data-pendo={`link-${status}`}
        aria-label={label}
        to={link}
      >
        <StatusIcon status={status as StatusType} size="12" />
        <span>{value || (projectsListV2 ? `--` : 0)}</span>
      </Link>
    </li>
  ) : (
    <li data-cy={`total-${status}`} data-pendo={`total-${status}`}>
      <span>
        <StatusIcon
          status={status as StatusType}
          size="12"
          data-cy={`status-icon-${status}`}
        />
        <span>{value || (projectsListV2 ? `--` : 0)}</span>
      </span>
    </li>
  )

  return showTooltip ? (
    <Tooltip placement="topRight" overlay={label}>
      {stat}
    </Tooltip>
  ) : (
    stat
  )
}

type RunStatsProps = {
  className?: string
  projectId?: string
  buildNumber?: number
  flaky?: number | null
  passed: number | null
  failed: number | null
  skipped: number | null
  pending: number | null
  expanded?: boolean
  showTooltip?: boolean
  projectsListV2?: boolean
  testsForReview?: boolean
  fullWidth?: boolean
  darkTheme?: boolean
  useFlakyIconV2?: boolean
}

const RunStats: FunctionComponent<RunStatsProps> = React.memo(
  ({
    className = '',
    projectId,
    buildNumber,
    skipped,
    pending,
    flaky,
    passed,
    failed,
    expanded = false,
    showTooltip = true,
    projectsListV2 = false,
    testsForReview = false,
    fullWidth = false,
    darkTheme,
    useFlakyIconV2,
  }) => {
    const testResultsUrl = `/projects/${projectId}/runs/${buildNumber}/test-results`
    const testResultsStatusRedirect = (status: string) => {
      if (!projectId || !buildNumber) {
        return null
      }

      const filters =
        status === 'flaky'
          ? {
              statuses: [],
              isFlaky: JSON.stringify([{ value: true, label: 'Flaky' }]),
            }
          : {
              statuses: JSON.stringify([
                { value: toUpper(status), label: toUpper(status) },
              ]),
              isFlaky: [],
            }

      const query = queryString.stringify(filters)

      return `${testResultsUrl}?${query}`
    }

    const renderFlakyBadge = () => {
      return <FlakyBadge count={flaky} />
    }

    const renderFlakyIcon = () => (
      <li className={styles.flakyIconV2}>
        <IconStatusFlaky size="10" />
        {flaky}
      </li>
    )

    const flakyTestResultsStatusRedirect = testResultsStatusRedirect('flaky')
    const hasFlaky = Boolean(flaky)
    const shouldBeFlakyLink = !!flakyTestResultsStatusRedirect

    let stats
    // the order of the stats is different based on this condition
    if (projectsListV2 || testsForReview) {
      stats = (
        <>
          <RunStat
            status="passed"
            value={passed}
            link={testResultsStatusRedirect('passed')}
            showTooltip={showTooltip}
            projectsListV2={projectsListV2}
          />
          <RunStat
            status="failed"
            value={failed}
            link={testResultsStatusRedirect('failed')}
            showTooltip={showTooltip}
            projectsListV2={projectsListV2}
          />
          {!testsForReview && (
            <RunStat
              status="skipped"
              value={skipped}
              link={testResultsStatusRedirect('skipped')}
              showTooltip={showTooltip}
              projectsListV2={projectsListV2}
            />
          )}
          <RunStat
            status="pending"
            value={pending}
            link={testResultsStatusRedirect('pending')}
            showTooltip={showTooltip}
            projectsListV2={projectsListV2}
          />
        </>
      )
    } else {
      stats = (
        <>
          {(expanded || Boolean(skipped)) && (
            <RunStat
              status="skipped"
              value={skipped}
              link={testResultsStatusRedirect('skipped')}
              showTooltip={showTooltip}
            />
          )}
          {(expanded || Boolean(pending)) && (
            <RunStat
              status="pending"
              value={pending}
              link={testResultsStatusRedirect('pending')}
              showTooltip={showTooltip}
            />
          )}
          {(expanded || Boolean(passed)) && (
            <RunStat
              status="passed"
              value={passed}
              link={testResultsStatusRedirect('passed')}
              showTooltip={showTooltip}
            />
          )}
          {(expanded || Boolean(failed)) && (
            <RunStat
              status="failed"
              value={failed}
              link={testResultsStatusRedirect('failed')}
              showTooltip={showTooltip}
            />
          )}
        </>
      )
    }

    return (
      <div
        data-cy="run-stats"
        className={cs(
          styles.runStats,
          className,
          fullWidth && styles.fullWidth,
          { [styles.darkTheme]: darkTheme }
        )}
      >
        {hasFlaky && !useFlakyIconV2 && (
          <span className={styles.flakyBadge} data-cy="run-stats-flaky-badge">
            {/* FIXME: flaky badge doesn't look right */}
            {shouldBeFlakyLink ? (
              <Link
                data-cy="run-header-flaky-badge"
                to={flakyTestResultsStatusRedirect}
              >
                {renderFlakyBadge()}
              </Link>
            ) : (
              renderFlakyBadge()
            )}
          </span>
        )}
        <ul className={styles.testResultStatuses}>
          {stats}
          {useFlakyIconV2 &&
            hasFlaky &&
            (shouldBeFlakyLink ? (
              <Link to={flakyTestResultsStatusRedirect}>
                {renderFlakyIcon()}
              </Link>
            ) : (
              renderFlakyIcon()
            ))}
        </ul>
      </div>
    )
  }
)

export { RunStats }
