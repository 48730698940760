import _ from 'lodash'
import { computed, observable } from 'mobx'

import { getAmountDifferenceForRecommendation } from '../../lib/utils'
import machinesStore from '../../machines/machines-store'

const roundToSecond = (duration) => 1000 * Math.floor(duration / 1000)

export class Recommendation {
  @observable run = null
  @observable numMachines = null
  @observable specs = null
  @observable groupDuration = null

  constructor(run, numMachines, specs, groupDuration) {
    // FIXME: Pass the entire group in?
    this.run = run
    this.numMachines = numMachines
    this.specs = specs
    this.groupDuration = groupDuration
  }

  /**
   * Whether this recommendation is complete and ready for presentation
   * @returns {boolean}
   */
  @computed get isReady() {
    return _.every(this.specs, 'isReadyForAnalysis')
  }

  /**
   * Whether this group can be parallelized
   * @returns {boolean}
   */
  @computed get canBeParallelized() {
    return this.run.specIsolation && this.specs.length > 1
  }

  /**
   * Whether this recommendation has a pitch message
   * @returns {boolean}
   */
  @computed get hasMessage() {
    return Boolean(this.pitchMessageType)
  }

  __generateProjection(n) {
    const machines = machinesStore.getCalculatedMachines(
      this.run,
      this.specs,
      n
    )

    // this is a circumstance where the specs didn't really finish running
    // so we don't want to try to calculate all these values and
    // end up with a NaN value
    if (_.isEmpty(machines)) {
      return null
    }

    // Add up all of the machine's durations to get total group duration
    let duration

    if (n === this.numMachines) {
      duration = this.groupDuration
    } else {
      duration = Math.max(..._.map(machines, 'totalDuration'))
    }

    // Round both the projected and actual duration to the nearest second to
    // ensure that the improvements "match" the rendered durations
    duration = roundToSecond(duration)
    const groupDuration = roundToSecond(this.groupDuration)
    const diff = getAmountDifferenceForRecommendation(groupDuration, duration)

    return {
      id: n,
      machines,
      numMachines: n,
      duration,
      differenceDuration: diff.duration,
      differencePercent: diff.percent,
      differenceDirection: diff.direction,
      isCurrent: this.numMachines ? n === this.numMachines : n === 1, // if 0, then not running in parallel, so it's 1 machine basically
      runtimePercent: 0,
      isFastest: false,
    }
  }

  /**
   * @private
   */
  @computed get __rawProjections() {
    if (!this.canBeParallelized) {
      return null
    }

    // Want to create a lower and upper range around a number
    // The numbers should never be negative - and should go 2 below current
    // and 10 above current
    let bottomRange = this.numMachines - 2
    let topRange = this.numMachines + 10

    if (this.numMachines <= 2) {
      bottomRange = 1
      topRange = 13
    }

    return _.range(bottomRange, topRange).map((n) => {
      return this.__generateProjection(n)
    })
  }

  /**
   * A runtime analysis detailing the current configuration for this group
   * @returns {(object|null)} a runtime analysis
   */
  @computed get current() {
    if (this.canBeParallelized) {
      return _.find(this.__rawProjections, 'isCurrent')
    }
  }

  /**
   * A runtime analysis detailing the slowest configuration for this group
   * @returns {(object|null)} a runtime analysis
   */
  @computed get slowest() {
    if (this.canBeParallelized) {
      return _.maxBy(this.__rawProjections, 'duration')
    }
  }

  /**
   * A runtime analysis detailing the fastest configuration for this group
   * @returns {(object|null)} a runtime analysis
   */
  @computed get fastest() {
    if (this.canBeParallelized) {
      return _.minBy(this.__rawProjections, 'duration')
    }
  }

  /**
   * The total time that is already being saved by running this group at its
   * current parellelization
   * @returns {(number|null)} duration in seconds
   */
  @computed get currentSaveTime() {
    if (this.canBeParallelized && this.current) {
      const trueSlowest = this.__generateProjection(1)

      return trueSlowest.duration - this.current.duration
    }
  }

  /**
   * The total time that could be saved by running this group at the
   * recommended parallelization
   * @returns {(number|null)} duration in seconds
   */
  @computed get potentialSaveTime() {
    if (this.canBeParallelized && this.current) {
      return this.current.duration - this.fastest.duration
    }
  }

  /**
   * The complete runtime analysis for this group
   * @returns {(Array|null)} A list of all runtime analyses
   */
  @computed get machineRows() {
    if (this.__rawProjections) {
      return this.__rawProjections.map((projection) => {
        return {
          ...projection,
          isFastest: projection === this.fastest,
          isCurrent: projection === this.current,
          runtimePercent: (100 * projection.duration) / this.slowest.duration, // fixme: slowest is no longer 1 machine
        }
      })
    }
  }

  /**
   * The type of recommendation for this group, if any
   * @returns {(string|null)}
   */
  @computed get pitchMessageType() {
    if (!this.isReady) {
      return null
    }

    // Ran on a version prior to v3, so specs were not run separately
    if (!this.run.specIsolation) {
      return 'oldVersion'
    }

    // Ran with only 1 spec file, but was short runtime
    if (this.specs.length === 1 && this.groupDuration < 180000) {
      // 3 mins
      return 'oneVeryFastSpec'
    }

    // Ran without passing --parallelization flag
    if (!this.run.supportsLoadBalancing) {
      return 'parallelizationPitch'
    }

    // Ran in parallel but with only 1 spec file w/ long run time
    if (this.specs.length === 1) {
      return 'splitSpecsPitch'
    }

    // Ran group with --parallel, multi machines and many specs
    if (this.current && this.fastest) {
      // But would run faster if they added more machines
      if (this.current.numMachines < this.fastest.numMachines) {
        return 'moreMachinesPitch'
      }

      // And is over allocating machines ☹️
      if (this.current.numMachines > this.fastest.numMachines) {
        return 'overAllocatingMachinePitch'
      }
    }

    // Ran group with --parallel, multi machines and many specs
    // And is running fastest it can 👍
    if (this.potentialSaveTime <= 0) {
      return 'goodParallelizationPitch'
    }
  }
}
