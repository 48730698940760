import { IntelligenceVariant } from './types'
import { features } from '~/lib/feature-flags'

export type IntelligenceFeatureFlags = {
  uiCovEnabled: boolean
  accessibilityEnabled: boolean
}

export type IntelligenceFeatureStatus = {
  isDisplayEnabled: boolean
  isProcessingEnabled: boolean
  isTrialExpired: boolean
  isProductEnabled: boolean
}

export const getIntelligenceFeatureStatus = (
  variant: IntelligenceVariant,
  featureFlags: IntelligenceFeatureFlags
): IntelligenceFeatureStatus => {
  const { uiCovEnabled, accessibilityEnabled } = featureFlags
  const isDisplayEnabled =
    variant === 'accessibility' ? accessibilityEnabled : uiCovEnabled

  const isProcessingEnabled =
    variant === 'accessibility'
      ? features.isEnabled('recording-service-discovery-process-accessibility')
      : features.isEnabled('recording-service-discovery-process-interactivity')

  const isTrialExpired = isDisplayEnabled && !isProcessingEnabled

  const isProductEnabled = isDisplayEnabled && isProcessingEnabled

  return {
    isDisplayEnabled,
    isProcessingEnabled,
    isTrialExpired,
    isProductEnabled,
  }
}
