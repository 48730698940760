export const APP_NOTIFICATION_SCOPES = [
  'ALL_RUNS',
  'UNSUCCESSFUL_RUNS',
  'SUCCESSFUL_RUNS',
] as const

export const APP_FAILED_NOTIFICATION_OPTION = {
  value: 'UNSUCCESSFUL_RUNS',
  label: 'Failed runs',
}

export const APP_PASSED_NOTIFICATION_OPTION = {
  value: 'SUCCESSFUL_RUNS',
  label: 'Passed runs',
}

export const APP_ALL_NOTIFICATION_OPTIONS = [
  APP_FAILED_NOTIFICATION_OPTION,
  APP_PASSED_NOTIFICATION_OPTION,
]

export const APP_INSTALL_SCOPES = ['ORGANIZATION', 'PROJECT'] as const
