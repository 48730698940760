import React, { FunctionComponent } from 'react'
import {
  ProjectBranchesFilterGlobal,
  TimeRangeFilter,
  TimeIntervalFilter,
  ProjectTagsFilterGlobal,
  ProjectRunGroupsFilterGlobal,
  ProjectCommittersFilterGlobal,
  ProjectSpecFilesFilter,
  ProjectBrowsersFilter,
  ProjectCypressVersionsFilter,
  ProjectOperatingSystemsFilter,
  ProjectTestStatusFilterGlobal,
  TestResultFlakyFilter,
  timeRangeAsDates,
} from '~/common/filters'
import { staticUTCDates } from '~/lib/utils'
import { LoadingFilter } from './LoadingFilter'
import { FilterReset } from '../FilterReset'
import * as analytics from '~/data/projects/analytics/hooks'
import { BuildIntervalFilter } from '~/common/filters/BuildIntervalFilter'

type FiltersProps = {
  flaky?: boolean | undefined
  branches?: boolean | undefined
  tags?: boolean | undefined
  timeRange?: boolean | undefined
  timeInterval?: boolean | undefined
  buildInterval?: boolean | undefined
  status?: boolean | undefined
  runGroups?: boolean | undefined
  committers?: boolean | undefined
  specFiles?: boolean | undefined
  browsers?: boolean | undefined
  cypressVersions?: boolean | undefined
  operatingSystems?: boolean | undefined
  familyId: string
}

const Filters: FunctionComponent<FiltersProps> = ({ familyId, ...show }) => {
  const branchesState = analytics.useBranchesFilter(familyId)
  const { timeRange, setTimeRange } = analytics.useTimeRangeFilter(familyId)
  const { timeInterval, setTimeInterval } =
    analytics.useTimeIntervalFilter(familyId)
  const { buildInterval, setBuildInterval } =
    analytics.useBuildIntervalFilter(familyId)
  const statusState = analytics.useStatusFilter(familyId)
  const tagsState = analytics.useTagsFilter(familyId)
  const runGroupsState = analytics.useRunGroupsFilter(familyId)
  const committersState = analytics.useCommittersFilter(familyId)
  const specFilesState = analytics.useSpecFilesFilter(familyId)
  const browsersState = analytics.useBrowsersFilter(familyId)
  const cypressVersionsState = analytics.useCypressVersionsFilter(familyId)
  const operatingSystemsState = analytics.useOperatingSystemsFilter(familyId)
  const flakyState = analytics.useFlakyFilter(familyId)

  const { resetFilters, isFilterSetToDefaultValue, filtersInitialized } =
    analytics.useFilterInfo(familyId)

  return (
    <>
      {show.branches && (
        <ProjectBranchesFilterGlobal
          id="analytics-branches"
          state={branchesState}
        />
      )}
      {show.tags && (
        <ProjectTagsFilterGlobal id="analytics-tags" state={tagsState} />
      )}
      {show.timeRange &&
        (timeRange ? (
          <TimeRangeFilter
            id="analytics-time-range"
            minDate={staticUTCDates.getStartOf1YearAgo()}
            value={timeRangeAsDates(timeRange)}
            onSelect={setTimeRange}
          />
        ) : (
          <LoadingFilter id="analytics-time-range" title="Time Range" />
        ))}
      {show.timeInterval &&
        (timeInterval ? (
          <TimeIntervalFilter
            id="analytics-time-interval"
            value={timeInterval}
            onSelect={setTimeInterval}
          />
        ) : (
          <LoadingFilter id="analytics-time-interval" title="Time Interval" />
        ))}
      {show.buildInterval &&
        (buildInterval ? (
          <BuildIntervalFilter
            id="analytics-build-interval"
            value={buildInterval}
            onSelect={setBuildInterval}
          />
        ) : (
          <LoadingFilter id="analytics-build-interval" title="Build Interval" />
        ))}
      {show.status && (
        <ProjectTestStatusFilterGlobal
          id="analytics-status"
          state={statusState}
        />
      )}
      {show.runGroups && (
        <ProjectRunGroupsFilterGlobal
          id="analytics-run-group"
          state={runGroupsState}
        />
      )}
      {show.committers && (
        <ProjectCommittersFilterGlobal
          id="analytics-committers"
          state={committersState}
        />
      )}
      {show.specFiles && (
        <ProjectSpecFilesFilter
          id="analytics-spec-files"
          state={specFilesState}
        />
      )}
      {show.browsers && (
        <ProjectBrowsersFilter id="analytics-browsers" state={browsersState} />
      )}
      {show.cypressVersions && (
        <ProjectCypressVersionsFilter
          id="analytics-cypress-versions"
          state={cypressVersionsState}
        />
      )}
      {show.operatingSystems && (
        <ProjectOperatingSystemsFilter
          id="analytics-operating-systems"
          state={operatingSystemsState}
        />
      )}
      {show.flaky && (
        <TestResultFlakyFilter
          id="analytics-flaky"
          selected={flakyState.selected || []}
          onChange={flakyState.setSelected}
        />
      )}
      {!isFilterSetToDefaultValue && filtersInitialized && (
        <FilterReset id="analytics" onClick={resetFilters} />
      )}
    </>
  )
}

export default Filters
