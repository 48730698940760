import _ from 'lodash'
import { action } from 'mobx'

import specsStore from '~/specs/specs-store'
import Spec from '~/specs/spec-model'
import { getSpecsByStatuses } from '~/specs/util'

import Machine from './machine-model'

class Machines {
  @action getMachines(specs: Spec[]) {
    return (
      _(specs)
        // an unclaimed spec will not have a machineId yet
        // do not map create a machine for this 'null' id
        .filter('machineId')
        .groupBy((spec) => spec.machineId)
        .map((machineSpecs, id) => {
          const firstSpec = _.first(machineSpecs) as Spec

          // only use specs that have ended already to caluclate this.
          const allEndedSpecs = _.filter(machineSpecs, (spec: Spec) => {
            return Boolean(spec.wallClockEndedAt)
          })

          const allStarts = specsStore.getAndSortSpecsTimeStamp(
            allEndedSpecs,
            'wallClockStartedAt'
          )

          const allEnds = specsStore.getAndSortSpecsTimeStamp(
            allEndedSpecs,
            'wallClockEndedAt'
          )

          const wallClockDuration =
            specsStore.getDurationBetweenFirstStartAndLastEnd(
              allStarts,
              allEnds
            )

          return new Machine({
            id,
            wallClockDuration,
            groupId: firstSpec ? firstSpec.group.id : undefined,
            specs: machineSpecs,
            browser: firstSpec?.browser ?? {},
            os: firstSpec?.os ?? {},
            status: specsStore.getStatus(machineSpecs),
            failed: _.sumBy(machineSpecs, 'totalFailed'),
            passed: _.sumBy(machineSpecs, 'totalPassed'),
            pending: _.sumBy(machineSpecs, 'totalPending'),
            skipped: _.sumBy(machineSpecs, 'totalSkipped'),
            wallClockStartedAt: _.first(allStarts),
            wallClockEndedAt: _.last(allEnds),
          })
        })
        .value()
    )
  }

  getNumMachines(specs) {
    return _(specs).map('machineId').compact().uniq().value().length
  }

  groupSpecsByMachine = (specs) => {
    return (
      _.chain(specs)
        // .filter('machineId') // remove any specs with null/undefined machineId
        .groupBy('machineId')
        .value()
    )
  }

  getCalculatedMachines(run, specs: Spec[], numFakeMachines) {
    // Specs without an estimate are distributed to machines first
    const estimatedDuration = (spec) =>
      spec.estimatedWallClockDuration || 999999999999999
    const specsWithDuration = _.filter(specs, (spec) => spec.duration) as Spec[]

    const completedSpecsWithDuration = getSpecsByStatuses<Spec>(
      specsWithDuration,
      ['ERRORED', 'TIMEDOUT', 'FAILED', 'PASSED', 'NOTESTS', 'CANCELLED']
    ) as Spec[]

    const completedSpecs = _.orderBy(
      completedSpecsWithDuration,
      estimatedDuration,
      'desc'
    )

    // Produce an array of fake machines with stats representing the previous runtime
    const newMachines: Machine[] = []
    for (let i = 0; i < completedSpecs.length; i++) {
      const spec = completedSpecs[i] as Spec

      const duration =
        +new Date(spec.postProcessingCompletedAt) - +new Date(spec.claimedAt)

      if (newMachines.length < numFakeMachines) {
        const machine = new Machine({
          id: _.uniqueId(),
          provisioningTime: 0, // When the machines were taking a long time to start, the estimated result was completely incorrect.
        })

        machine.addSpec(spec)
        machine.addDuration(duration)
        newMachines.push(machine)
      } else {
        const shortest = _.minBy(newMachines, 'currentDuration')

        if (shortest) {
          shortest.addSpec(spec)
          shortest.addDuration(duration)
        }
      }
    }

    return newMachines
  }

  getStatus(instances) {
    return specsStore.getStatus(instances)
  }
}

export default new Machines()
