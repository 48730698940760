import { createContext, useContext } from 'react'
import {
  getIntelligenceFeatureStatus,
  IntelligenceFeatureStatus,
} from '../intelligence-utils/getIntelligenceFeatureStatus'
import {
  getIntelligenceRunSummaryUi,
  IntelligenceRunSummaryUi,
  IntelligenceRunSummaryUiData,
} from '../intelligence-utils/getIntelligenceRunSummaryUi'
import { IntelligenceVariant } from '../intelligence-utils/types'

export type AQFeatureFlagsContextShape = {
  uiCovEnabled: boolean
  accessibilityEnabled: boolean
  accessibilityAxeRuleStatuses: boolean
}

export const AQFeatureFlagsContext =
  createContext<AQFeatureFlagsContextShape | null>(null)

export const useAQFeatureFlagsContext = () => {
  const value = useContext(AQFeatureFlagsContext)

  if (!value) {
    throw new Error(
      'Cannot use AQFeatureFlagsContext outside of an AQFeatureFlagsContext.Provider'
    )
  }

  const { uiCovEnabled, accessibilityEnabled } = value

  const getFeatureStatus = (
    variant: IntelligenceVariant
  ): IntelligenceFeatureStatus => {
    return getIntelligenceFeatureStatus(variant, {
      uiCovEnabled,
      accessibilityEnabled,
    })
  }

  const getRunSummaryUi = (
    variant: IntelligenceVariant,
    run: IntelligenceRunSummaryUiData
  ): IntelligenceRunSummaryUi => {
    return getIntelligenceRunSummaryUi(variant, run, getFeatureStatus(variant))
  }

  return { ...value, getFeatureStatus, getRunSummaryUi }
}
