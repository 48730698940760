import * as React from 'react'
import { Link } from '@reach/router'
import { CustomIcon } from './custom-icons/CustomIcon'

export interface LoginHeaderProps {
  showBackButton?: boolean
  isTestRunner?: boolean
  isSignupRoot?: boolean
}

export const LoginHeader: React.FC<LoginHeaderProps> = ({
  showBackButton,
  isTestRunner,
  isSignupRoot,
}) => {
  return (
    <div
      id="login-header"
      className="flex items-center border-solid border-b border-gray-100 bg-white h-[7rem]"
    >
      <div className="grow flex justify-between items-center">
        <div className="<md:hidden md:pl-12">
          <img
            className="cypress-header-logo <md:hidden"
            alt="Cypress"
            src="/img/cypress-logo-light-bg.svg"
          />
        </div>
        <div className="w-50 float-left">
          {showBackButton && (
            <Link
              to={
                isSignupRoot
                  ? isTestRunner
                    ? '/test-runner-signup'
                    : '/signup'
                  : isTestRunner
                    ? '/test-runner-login'
                    : '/login'
              }
              className="btn btn-link btn-block text-indigo-500"
            >
              <CustomIcon name="arrow-default-left-medium" size="x16" /> Back
            </Link>
          )}
        </div>
        <div className="grow text-center">
          <img
            className="cypress-header-logo h-20 w-20 md:hidden"
            alt="Cypress"
            src="/img/cypress-logo-round-light-bg.png"
          />
        </div>
        <div className="w-50 float-right">
          <Link
            to={
              isSignupRoot
                ? isTestRunner
                  ? '/test-runner-login'
                  : '/login'
                : isTestRunner
                  ? '/test-runner-signup'
                  : '/signup'
            }
            className="btn btn-link btn-block text-indigo-500"
          >
            {isSignupRoot ? 'Log in' : 'Sign up'}
          </Link>
        </div>
      </div>
    </div>
  )
}
