import React from 'react'
import Button from '@cypress-design/react-button'
import { IconTechnologyDashboardFail } from '@cypress-design/react-icon'
import styles from './ErrorContentOverride.module.scss'
import { useBrowser } from 'use-ua-parser-js'
import type { FallbackProps } from 'react-error-boundary'
import semver from 'semver'

const isLocalhost = () =>
  window.location.hostname === 'localhost' ||
  window.location.hostname === '0.0.0.0'

interface ErrorContentOverrideProps extends FallbackProps {
  hasPaidPlan: boolean
}

function isSemverLessThan(s1: string, s2: string) {
  function normalizeVersion(version: string): string {
    const parts = version.split('.').map((part) => parseInt(part, 10))
    while (parts.length < 3) {
      parts.push(0) // Append 0 for missing minor or patch version
    }
    return parts.join('.')
  }
  const v1 = normalizeVersion(s1)
  const v2 = normalizeVersion(s2)
  return semver.lt(v1, v2)
}

export const ErrorContentOverride = (props: ErrorContentOverrideProps) => {
  const { hasPaidPlan, error } = props
  const browser = useBrowser()

  if (!error) {
    return null
  }

  let title: string = 'Something went wrong'
  let message: string | React.ReactElement =
    `There was a problem with this Test Replay. Our team has been notified. If the problem persists, please ${
      hasPaidPlan ? 'contact support' : 'try again later'
    }.`

  if (error.message === 'Expired S3 url') {
    // when running locally URL is hardcoded so don't reload to avoid infinite loop
    if (isLocalhost()) {
      message = `The URL for this Test Replay has expired. Please refresh the page or update the S3 bucket URL.`
    } else {
      window.location.reload() // reload to get new URL
    }
  }

  // CSSStyleSheetAPI is fully supported starting in Safari 16.4. See https://caniuse.com/?search=cssstylesheet
  const isBrowserIncompatible: boolean =
    browser.name === 'Safari' && isSemverLessThan(browser.version, '16.4')
  if (isBrowserIncompatible) {
    title = 'Unsupported browser'
    message = (
      <>
        Test Replay is not supported in Safari {browser.version}. Please upgrade
        to Safari{<>&nbsp;</>}16.4 or later to use Test Replay.
      </>
    )
  }

  return (
    <div
      className={styles.errorContentOverride}
      data-cy="error-content-override"
    >
      <div className={styles.errorContentWrapper}>
        <IconTechnologyDashboardFail
          strokeColor="gray-400"
          fillColor="gray-800"
        />
        <h2>{title}</h2>
        <span data-cy="error-message-text">{message}</span>
        {!isBrowserIncompatible && (
          <a
            data-cy="button-link"
            // Note: empty path induces reload of full URL as is:
            href={hasPaidPlan ? 'mailto:support@cypress.io' : ''}
          >
            <Button>{hasPaidPlan ? 'Contact support' : 'Refresh page'}</Button>
          </a>
        )}
      </div>
    </div>
  )
}
