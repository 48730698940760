export const CURRENCIES_TO_CONVERT = [
  'USD',
  'GBP',
  'EUR',
  'RUB',
  'INR',
] as const

export const CURRENCY_SYMBOLS: Record<CurrenciesToConvert, string> = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  RUB: '₽',
  INR: '₹',
}

export type CurrenciesToConvert = (typeof CURRENCIES_TO_CONVERT)[number]

export const CURRENCY_PICKER = CURRENCIES_TO_CONVERT.map((c) => ({
  value: c,
  label: `${c} ${CURRENCY_SYMBOLS[c]}`,
}))
