import _ from 'lodash'
import { action, observable } from 'mobx'

import specsStore from '../specs/specs-store'
import Group from './group-model'
import Spec from '../specs/spec-model'

class Groups {
  @observable groups = []

  @action getGroups(specs: Spec[]) {
    return _(specs)
      .groupBy('group.id')
      .map((groupedSpecs, id) => {
        const firstSpec = _.first(groupedSpecs)
        const uniqMachines = _.chain(groupedSpecs)
          .map('machineId')
          .uniq()
          .value()

        _.each(groupedSpecs, (spec) => {
          spec.update({
            machineIndex: spec.machineId
              ? _.indexOf(uniqMachines, spec.machineId) + 1
              : null,
          })
        })

        // only use instances that have ended already to calculate this.
        const allEndedSpecs = _.filter(groupedSpecs, (spec) => {
          return Boolean(spec.wallClockEndedAt)
        })

        const allStarts = specsStore.getAndSortSpecsTimeStamp(
          allEndedSpecs,
          'wallClockStartedAt'
        )

        const allEnds = specsStore.getAndSortSpecsTimeStamp(
          allEndedSpecs,
          'wallClockEndedAt'
        )

        const groupDuration = specsStore.getDurationBetweenFirstStartAndLastEnd(
          allStarts,
          allEnds
        )

        const numMachines = _.filter(
          uniqMachines,
          (machine) => !_.isNull(machine)
        ).length

        return new Group({
          id,
          groupDuration,
          specs: groupedSpecs,
          name: firstSpec ? firstSpec.group.name : '',
          browser: firstSpec?.browser ?? {},
          os: firstSpec?.os ?? {},
          status: this.getStatus(groupedSpecs),
          failed: _.sumBy(groupedSpecs, 'totalFailed'),
          passed: _.sumBy(groupedSpecs, 'totalPassed'),
          pending: _.sumBy(groupedSpecs, 'totalPending'),
          skipped: _.sumBy(groupedSpecs, 'totalSkipped'),
          muted: _.sumBy(groupedSpecs, 'totalMutedTests'),
          numMachines,
          sumDuration: _.sumBy(groupedSpecs, 'duration'),
          // Assumption: run groups can only have tests of the same testingType.
          // Hence, sampling the testingType of the first spec is sufficient:
          testingType: firstSpec ? firstSpec.testingType : '',
        })
      })
      .sortBy('name')
      .value()
  }

  getStatus(instances) {
    return specsStore.getStatus(instances, { includeCanceledStatus: true })
  }
}

export default new Groups()
