import type { BrowserName } from '@packages/types'

export const RECORDING_SERVICE_EXPIRATION_TIME = 60 * 60 * 24

export const CHROMIUM_BROWSERS: Array<BrowserName> = [
  'electron',
  'chrome',
  'custom chrome',
  'google chrome',
  'chrome beta',
  'canary',
  'chrome canary',
  'chrome for testing',
  'custom chrome for testing',
  'chromium',
  'custom chromium',
  'edge',
  'custom edge',
  'edge beta',
  'edge canary',
  'edge dev',
  'brave',
  'chromium (npm)',
  'opera',
  'chromep',
  'local electron app',
  'chromium for bamboo',
]

export const DEFAULT_BURN_IN_CONFIG = { default: 3, flaky: 5 }
