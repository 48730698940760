import React from 'react'
import Tag from '@cypress-design/react-tag'
import {
  IconActionDeleteMedium,
  IconActionExportSmall,
  IconObjectInteractivityOutline,
  IconCypressAccessibilityOutline,
} from '@cypress-design/react-icon'
import Button from '@cypress-design/react-button'

import styles from './module.SidebarPromotions.scss'

const AQ_LOCAL_STORAGE_KEY = 'dismissed-aq-project-nav-promo'

interface SidebarPromotionsProps {
  orgId: string
  uiCovEnabled: boolean
  accessibilityEnabled: boolean
}

interface PromotionProps {
  handleOnDismiss: () => void
  promoTitle: string
  promoLinks: PromoLinkProps[]
}

interface PromoLinkProps {
  linkText: string
  link: string
  dataPendo: string
  Icon: React.FunctionComponent<any>
}

const PromoLink: React.FunctionComponent<PromoLinkProps> = ({
  linkText,
  link,
  dataPendo,
  Icon,
}) => (
  <li>
    <a
      className={styles.item}
      data-cy={dataPendo}
      data-pendo={dataPendo}
      target="_blank"
      rel="noopener noreferrer"
      href={link}
    >
      <Icon
        className={styles.productIcon}
        strokeColor="gray-500"
        fillColor="gray-900"
      />
      {linkText}
      <IconActionExportSmall
        data-cy="open-promo-link"
        className={styles.openLinkIcon}
        strokeColor="gray-500"
        secondaryStrokeColor="gray-700"
      />
    </a>
  </li>
)

const Promotion: React.FunctionComponent<PromotionProps> = ({
  promoTitle,
  promoLinks,
  handleOnDismiss,
}) => {
  return (
    <div className={styles.container} data-cy="sidebar-promo">
      <div className={styles.titleContainer}>
        <span className={styles.title}>
          {promoTitle}
          <Tag size="20" color="jade" className={styles.tag}>
            New
          </Tag>
        </span>
        <Button
          onClick={handleOnDismiss}
          data-cy="dismiss-sidebar-promo"
          variant="outline-dark"
          className={styles.dismiss}
          size="20"
        >
          <IconActionDeleteMedium size="16" />
        </Button>
      </div>
      <ul>
        {promoLinks.map((props) => (
          <PromoLink key={props.dataPendo} {...props} />
        ))}
      </ul>
    </div>
  )
}

const SidebarPromotions: React.FunctionComponent<SidebarPromotionsProps> = ({
  orgId,
  uiCovEnabled,
  accessibilityEnabled,
}) => {
  const [dismissedByUser, setDismissedByUser] = React.useState(
    localStorage.getItem(AQ_LOCAL_STORAGE_KEY) ?? 'false'
  )

  const handleOnDismiss = () => {
    localStorage.setItem(AQ_LOCAL_STORAGE_KEY, 'true')
    setDismissedByUser('true')
  }

  if (dismissedByUser === 'true') return null

  const aqPromoLinks: PromoLinkProps[] = []

  if (!uiCovEnabled) {
    aqPromoLinks.push({
      linkText: 'UI Coverage',
      link: `https://on.cypress.io/ui-coverage-trial?${new URLSearchParams({
        organization_uuid: orgId,
        utm_campaign: '2024q1_intelligence-features-waitlist',
        utm_source: 'cypress-cloud',
        utm_medium: 'sidenav',
        utm_content: 'UI Coverage',
      })}`,
      Icon: IconObjectInteractivityOutline,
      dataPendo: 'ui-coverage-trial-link',
    })
  }

  if (!accessibilityEnabled) {
    aqPromoLinks.push({
      linkText: 'Cypress Accessibility',
      link: `https://on.cypress.io/accessibility-trial?${new URLSearchParams({
        organization_uuid: orgId,
        utm_campaign: '2024q1_intelligence-features-waitlist',
        utm_source: 'cypress-cloud',
        utm_medium: 'sidenav',
        utm_content: 'Cypress Accessibility',
      })}`,
      Icon: IconCypressAccessibilityOutline,
      dataPendo: 'accessibility-trial-link',
    })
  }

  if (aqPromoLinks.length === 0) return null

  return (
    <Promotion
      promoTitle="Application Quality"
      handleOnDismiss={handleOnDismiss}
      promoLinks={aqPromoLinks}
    />
  )
}

export { SidebarPromotions }
