import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Modal, Nav } from '@frontend/design-system'
import styles from './module.CreateJiraIssuePrompt.scss'
import { useJiraProjectsQuery } from '~/graphql-codegen-operations.gen'
import { JiraSelect } from './JiraSelect'
import { useDebounce } from 'use-debounce'
import { CreateJiraIssueForm } from './CreateJiraIssueForm'
import _ from 'lodash'
import cs from 'clsx'
import { LinkJiraIssue } from './LinkJiraIssue'

type CreateJiraIssuePromptProps = {
  orgId: string
  summary: string
  showModal: boolean
  updateShowModal: React.Dispatch<React.SetStateAction<boolean>>
  specHash: string
  titleHash: string
  description: string
  screenshotId?: string
}

export const CreateJiraIssuePrompt: FunctionComponent<
  CreateJiraIssuePromptProps
> = ({
  orgId,
  summary,
  showModal,
  updateShowModal,
  specHash,
  titleHash,
  description,
  screenshotId,
}) => {
  const [selectedTab, setSelectedTab] = useState<'create' | 'link'>('create')
  const [selectedProjectId, setSelectedProjectId] = useState<string>()
  const [searchInput, setSearchInput] = useState<string>()
  const [debouncedSearchInput] = useDebounce(searchInput, 400)

  const { data, loading } = useJiraProjectsQuery({
    variables: {
      orgId,
      search: debouncedSearchInput,
    },
  })

  useEffect(() => {
    if (
      !selectedProjectId &&
      data?.organization.jiraProjects.nodes.length &&
      !debouncedSearchInput
    ) {
      setSelectedProjectId(data.organization.jiraProjects.nodes[0].id)
    }
  }, [data, debouncedSearchInput, selectedProjectId])

  const onHide = useCallback(() => {
    updateShowModal(false)
  }, [updateShowModal])

  if (!data && loading) {
    return <p>Loading...</p>
  }

  const options = _.map(data?.organization.jiraProjects.nodes, (node) => ({
    label: node.name,
    value: node.id,
  }))

  const keyedOptions = _.keyBy(options, 'value')

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      backdrop
      data-cy="create-jira-issue-prompt"
    >
      <Modal.Header closeButton>
        <h3 className="modal-title">Create Jira issue</h3>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <Nav bsStyle="tabs" className="nav nav-tabs run-tabs">
          <li
            role="presentation"
            className={cs({
              active: selectedTab === 'create',
            })}
          >
            <button onClick={() => setSelectedTab('create')}>
              <div className="nav-item--tab">Create</div>
            </button>
          </li>
          <li
            role="presentation"
            className={cs({
              active: selectedTab === 'link',
            })}
          >
            <button onClick={() => setSelectedTab('link')}>
              <div className="nav-item--tab">Link</div>
            </button>
          </li>
        </Nav>

        <div className={styles.bodyForm}>
          {(() => {
            if (selectedTab === 'create') {
              return (
                <>
                  <JiraSelect
                    label="Project"
                    instanceId="jira-project-autocomplete"
                    defaultOption={{
                      label: selectedProjectId
                        ? keyedOptions[selectedProjectId]?.label
                        : '',
                      value: selectedProjectId
                        ? keyedOptions[selectedProjectId]?.value
                        : '',
                    }}
                    options={options}
                    isLoading={loading}
                    onInputChange={setSearchInput}
                    onChange={(value) => setSelectedProjectId(value.value)}
                    placeholder="Search your Jira project"
                  />

                  {selectedProjectId && (
                    <CreateJiraIssueForm
                      orgId={orgId}
                      selectedProjectId={selectedProjectId}
                      summary={summary}
                      updateShowModal={updateShowModal}
                      specHash={specHash}
                      titleHash={titleHash}
                      description={description}
                      screenshotId={screenshotId}
                    />
                  )}
                </>
              )
            }

            if (selectedTab === 'link') {
              return (
                <LinkJiraIssue
                  orgId={orgId}
                  specHash={specHash}
                  titleHash={titleHash}
                  updateShowModal={updateShowModal}
                />
              )
            }

            return null
          })()}
        </div>
      </Modal.Body>
    </Modal>
  )
}
