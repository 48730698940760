import {
  IconBrowserChrome,
  IconBrowserChromeBeta,
  IconBrowserEdge,
  IconBrowserChromeCanary,
  IconBrowserElectronLight,
  IconBrowserMozillaFirefox,
  IconBrowserWebkit,
} from '@cypress-design/react-icon'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import { getLogoPath, getBrowserLogoName } from '@packages/common'

interface BrowserIconProps extends React.HTMLProps<HTMLLinkElement> {
  className?: string
  spin?: boolean
  alt: string
  name: string
}

// Create a new method for Browser icons, it tries to get the used icon, but
// if it doesn't exists as fallback it displays the fa-globe icon, in that transition
// for some ms there's a broken image. We only want to display it in few places,
// instead of all the possible icons rendered on the page.
const BrowserIcon: FunctionComponent<BrowserIconProps> = ({
  spin,
  name,
  className = '',
  alt,
  ...rest
}) => {
  switch (getBrowserLogoName(name)) {
    case 'chrome':
      return <IconBrowserChrome className={className} />
    case 'chrome_beta':
      return <IconBrowserChromeBeta className={className} />
    case 'canary':
      return <IconBrowserChromeCanary className={className} />
    case 'chrome_canary':
      return <IconBrowserChromeCanary className={className} />
    case 'electron':
      return <IconBrowserElectronLight className={className} />
    case 'firefox':
      return <IconBrowserMozillaFirefox className={className} />
    case 'edge':
      return <IconBrowserEdge className={className} />
    case 'web_kit':
      return <IconBrowserWebkit className={className} />
    default:
      break
  }

  const logoPath = getLogoPath(name)

  if (logoPath) {
    return (
      <img
        alt={alt}
        data-cy="browser-icon"
        data-pendo="browser-icon"
        className={`browser-icon-img ${className}`}
        src={logoPath}
      />
    )
  }

  const iconClassName = clsx(
    'fa fa-globe',
    {
      'fa-spin': spin,
    },
    className
  )

  return <i className={iconClassName} aria-hidden {...rest} />
}

export { BrowserIcon }
