import React, { FunctionComponent } from 'react'
import LoadingIndicator from 'react-loading-overlay'

type LoadingContainerProps = {
  active: boolean
  className?: string
  children: any
  fadeOnlyOnDeactivate?: boolean
  overlayZIndex?: number
  spinner?: React.ReactNode
}

export const LoadingContainer: FunctionComponent<LoadingContainerProps> = ({
  active,
  className,
  children,
  fadeOnlyOnDeactivate,
  overlayZIndex,
  spinner,
}) => {
  const generateOverlayStyles = (base) => {
    const overlayStyles = {
      ...base,
      background: 'rgba(255,255, 255, 0.8)',
    }

    if (overlayZIndex !== undefined) {
      overlayStyles.zIndex = `${overlayZIndex}`
    }

    return overlayStyles
  }

  return (
    <LoadingIndicator
      active={active}
      className={className}
      spinner={spinner ?? true}
      fadeSpeed={fadeOnlyOnDeactivate && active ? 0 : undefined}
      styles={{
        overlay: generateOverlayStyles,
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#4997e4',
          },
        }),
      }}
    >
      {children}
    </LoadingIndicator>
  )
}
