import { lowerCase } from 'lodash'

const logoPaths = {
  chrome: '/img/chrome_32x32.png',
  chrome_beta: '/img/chrome-beta_32x32.png',
  // canary has been renamed to chrome_canary but we keep this for backwards compatibility
  canary: '/img/chrome-canary_32x32.png',
  chrome_canary: '/img/chrome-canary_32x32.png',
  chrome_for_testing: '/img/chrome-test_32x32.png',
  custom_chrome_for_testing: '/img/chrome-test_32x32.png',
  chromium: '/img/chromium_32x32.png',
  custom_chromium: '/img/chromium_32x32.png',
  edge: '/img/edge_32x32.png',
  edge_beta: '/img/edge-beta_32x32.png',
  edge_canary: '/img/edge-canary_32x32.png',
  edge_dev: '/img/edge-dev_32x32.png',
  electron: '/img/electron_32x32.png',
  firefox: '/img/firefox_32x32.png',
  firefox_developer_edition: '/img/firefox-developer-edition_32x32.png',
  firefox_nightly: '/img/firefox-nightly_32x32.png',
  web_kit: '/img/webkit_32x32.png',
}

export const getBrowserLogoName = (name: string): keyof typeof logoPaths =>
  lowerCase(name).replace(/ /g, '_') as keyof typeof logoPaths

export const getLogoPath = (name: string) => {
  const browserName = getBrowserLogoName(name)
  return logoPaths[browserName]
}
