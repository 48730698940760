import React from 'react'
import { Tooltip } from '@frontend/design-system'
import styles from './module.IntelligenceScoresPill.scss'
import { A11yMiniIcon, UiCoverageMiniIcon } from '../intelligence-utils/icons'
import { Link } from '@reach/router'
import { intelligenceVariants } from '../intelligence-utils/util'
import {
  IntelligenceRunSummaryUi,
  IntelligenceRunSummaryUiData,
} from '../intelligence-utils/getIntelligenceRunSummaryUi'
import { uiCoverageDefaultPath } from '../../uiCoverageNavigation'
import { IntelligenceVariant } from '../intelligence-utils/types'
import cs from 'clsx'

export type RunSummaryUi = (
  variant: IntelligenceVariant,
  run: IntelligenceRunSummaryUiData
) => IntelligenceRunSummaryUi

type IntelligenceScoresPillProps = {
  run: IntelligenceRunSummaryUiData
  basePath: string
  getRunSummaryUi: RunSummaryUi
  disableLink?: boolean
  className?: string
  darkTheme?: boolean
}

export const IntelligenceScoresPill: React.FC<IntelligenceScoresPillProps> = ({
  run,
  basePath,
  getRunSummaryUi,
  disableLink = false,
  className,
  darkTheme,
}) => {
  const pills = intelligenceVariants
    .map((variant) => {
      const { intelligenceScoresPill: pill } = getRunSummaryUi(variant, run)
      if (pill) {
        const viewTypePath =
          variant === 'ui-coverage' ? uiCoverageDefaultPath : variant
        const content = (
          <>
            {variant === 'accessibility' ? (
              <A11yMiniIcon />
            ) : (
              <UiCoverageMiniIcon />
            )}
            {pill.value}
          </>
        )

        if (disableLink) {
          return (
            <div
              className={styles.pillContent}
              data-cy={`intelligence-score-pill-${variant}`}
              key={`content-${variant}`}
            >
              {content}
            </div>
          )
        }

        return (
          <Tooltip placement="top" overlay={() => pill.popper} key={variant}>
            <Link
              to={`${basePath}/${viewTypePath}`}
              className={styles.section}
              data-cy={pill.dataCy}
            >
              {content}
            </Link>
          </Tooltip>
        )
      }
      return null
    })
    .filter(Boolean)

  if (pills.length) {
    return (
      <div
        className={cs(styles.container, className, {
          [styles.darkTheme]: darkTheme,
        })}
        data-cy="intelligence-score-pill"
      >
        {pills}
      </div>
    )
  }

  return null
}
