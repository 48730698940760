import {
  EmptyState,
  Panel,
  TextEmptyState,
  Tooltip,
} from '@frontend/design-system'
import {
  ProjectRunDurationsOverRunsQuery,
  ProjectRunsConnectionInput,
  useRunsListLazyQuery,
} from '~/graphql-codegen-operations.gen'
import cs from 'clsx'
import commaNumber from 'comma-number'
import _ from 'lodash'
import pluralize from 'pluralize'
import React, { FunctionComponent } from 'react'
import { AnalyticRowLink } from '../AnalyticRowLink/AnalyticRowLink'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'

import { durationFormattedFull } from '~/lib/utils'

import { AnalyticsProgressBar } from '../AnalyticsProgressBar'
import styles from '../module.AnalyticsTable.scss'
import { RunsListItem } from '~/runs/RunsListItem'
import { useScrollToReference } from '~/hooks'
import { useAQFeatureFlagsContext } from '~/common/intelligence/feature-flags/AQFeatureFlagsContext'

type RunDurationDrillDownTableProps = {
  data?: ProjectRunDurationsOverRunsQuery
  projectId: string
  isLoading: boolean
  activeFilters?: Partial<ProjectRunsConnectionInput>
  focusedNode?: { date: string; endDate?: string; count: number }
}

export const RunDurationDrillDownTable: FunctionComponent<
  RunDurationDrillDownTableProps
> = ({ data, isLoading, projectId, activeFilters, focusedNode }) => {
  const [isDrilledDownRecords, setDrillDown] = React.useState<number[]>([])
  const toggleDrillDown = (index: number) => {
    if (isDrilledDownRecords.includes(index)) {
      setDrillDown(isDrilledDownRecords.filter((v) => v !== index))
    } else {
      setDrillDown([...isDrilledDownRecords, index])
    }
  }
  const nodes = data?.metrics.projectRunDurationsOverTime.nodes || []
  const metrics = React.useMemo(() => _.reverse(_.cloneDeep(nodes)), [nodes])

  // Adds focused node to drilled down records
  React.useEffect(() => {
    const focusedGroup = metrics.findIndex(
      (row) =>
        focusedNode?.date === row.timeSeriesPointStartDatetime &&
        focusedNode?.endDate === row.timeSeriesPointEndDatetime
    )
    setDrillDown([...isDrilledDownRecords, focusedGroup])
  }, [focusedNode, focusedNode?.count || 0])

  if (!data?.metrics) {
    return (
      <Panel>
        <EmptyState>
          <span>Loading...</span>
        </EmptyState>
      </Panel>
    )
  }

  return (
    <LoadingContainer active={isLoading}>
      <Panel>
        {metrics.length < 1 ? (
          <EmptyState>
            <span>No data for this time range.</span>
          </EmptyState>
        ) : (
          <div
            className={cs(styles.analyticsTable, styles.runDurationTable)}
            data-cy="run-duration-table"
          >
            <TableHeader />
            {metrics.map((row, index) => {
              const isFocused =
                focusedNode?.date === row.timeSeriesPointStartDatetime &&
                focusedNode?.endDate === row.timeSeriesPointEndDatetime
              const isDrillingDown = isDrilledDownRecords.includes(index)
              return (
                <DrillDownRow
                  key={`${row.timeSeriesPointStartDatetime}-${row.timeSeriesPointEndDatetime}`}
                  activeFilters={activeFilters}
                  isDrillingDown={isDrillingDown}
                  scrollToTracker={isFocused ? focusedNode?.count : undefined}
                  groupNumber={index + 1}
                  row={row}
                  projectId={projectId}
                  onClick={() => toggleDrillDown(index)}
                />
              )
            })}
          </div>
        )}
      </Panel>
    </LoadingContainer>
  )
}

const DrillDownRow = ({
  projectId,
  groupNumber,
  row,
  isDrillingDown,
  scrollToTracker,
  onClick,
  activeFilters,
}: {
  row: ProjectRunDurationsOverRunsQuery['metrics']['projectRunDurationsOverTime']['nodes'][number]
  groupNumber: number
  projectId: string
  isDrillingDown: boolean
  scrollToTracker?: number
  onClick: VoidFunction
  activeFilters: RunDurationDrillDownTableProps['activeFilters']
}) => {
  const [ref, doScroll] = useScrollToReference()
  const [doQuery, { data, loading }] = useRunsListLazyQuery()

  const aqFeatureFlags = useAQFeatureFlagsContext()

  const { uiCovEnabled, accessibilityEnabled } = aqFeatureFlags

  const intelligenceEnabled = accessibilityEnabled || uiCovEnabled

  const variables = React.useMemo(
    () => ({
      projectId,
      input: {
        ...(activeFilters || {}),
        perPage: 100,
        dateTimeRange: {
          startDate: row.timeSeriesPointStartDatetime,
          endDate: row.timeSeriesPointEndDatetime,
        },
      },
      accessibilityFlagEnabled: accessibilityEnabled,
      interactivityFlagEnabled: uiCovEnabled,
      intelligenceEnabled,
    }),
    [
      projectId,
      row,
      activeFilters,
      accessibilityEnabled,
      uiCovEnabled,
      intelligenceEnabled,
    ]
  )
  const prefetch = React.useCallback(() => {
    if (!data) {
      doQuery({ variables })
    }
  }, [data, variables, doQuery])

  // Query for drilled down runs
  React.useEffect(() => {
    if (isDrillingDown) {
      doQuery({ variables })
    }
  }, [isDrillingDown, doQuery])

  // Scroll to focused target
  React.useEffect(() => {
    if (!loading && isDrillingDown && scrollToTracker) {
      doScroll()
    }
  }, [scrollToTracker, loading, isDrillingDown])
  return (
    <>
      <AnalyticRowLink
        dataCy={`run-duration-table__row__${groupNumber}`}
        onClick={onClick}
        onMouseOver={prefetch}
        className={styles.rowHasDrillIn}
      >
        <div ref={ref} className={styles.scrollToHelper}>
          Group {groupNumber}
        </div>
        <div>
          {row.averageRuntime ? (
            <span className={styles.textRight}>
              {durationFormattedFull(row.averageRuntime * 1000, 2)}
            </span>
          ) : (
            <span className={styles.textRight}>
              <TextEmptyState />
            </span>
          )}
        </div>
        <div>
          <span className={styles.textRight}>
            {row.medianConcurrency ? (
              <>
                {Math.round(row.medianConcurrency)}{' '}
                {pluralize('machine', Math.round(row.medianConcurrency))}
              </>
            ) : (
              <TextEmptyState />
            )}
          </span>
        </div>
        <div>
          <span className={styles.textRight}>
            {row.parallelizationSavings ? (
              <span>
                {durationFormattedFull(row.parallelizationSavings * 1000, 2)}
              </span>
            ) : (
              <TextEmptyState />
            )}
          </span>
        </div>
        <div>
          <span
            className={cs(styles.textRight, {
              'text-muted': row.totalRuns === 0,
            })}
          >
            {commaNumber(row.totalRuns || 0)}
          </span>
        </div>
        <div>
          <AnalyticsProgressBar rate={row.failureRate} />
        </div>
      </AnalyticRowLink>
      <div
        data-cy={`drill-down-panel__${groupNumber}`}
        className={cs(styles.drillDownPanel, {
          [styles.collapsed]: loading || !data?.project.runs.nodes,
        })}
      >
        {isDrillingDown && (!loading || data?.project.runs.nodes) ? (
          <div
            data-cy={`drill-down-panel__${groupNumber}_open`}
            className={styles.drillDownContent}
          >
            {(data?.project.runs.nodes || []).map((run, idx) => (
              <RunsListItem key={idx} run={run} projectId={projectId} />
            ))}
          </div>
        ) : null}
      </div>
    </>
  )
}

const TableHeader = () => {
  return (
    <AnalyticRowLink dataCy="run-duration-table__header">
      <h6>Run Group</h6>
      <h6>
        <span className={styles.textRight}>Average runtime</span>
      </h6>
      <h6>
        <span className={styles.textRight}>
          <Tooltip
            overlay="The median number of machines that were used for parallelizing for these runs"
            placement="top"
          >
            <span>Typical concurrency ℹ</span>
          </Tooltip>
        </span>
      </h6>
      <h6>
        <span className={styles.textRight}>
          Time saved from parallelization
        </span>
      </h6>
      <h6>
        <span className={styles.textRight}>Total runs</span>
      </h6>
      <h6>
        <span className={styles.textRight}>Failure rate</span>
      </h6>
    </AnalyticRowLink>
  )
}
