import { LockedIcon, LockedMiniIcon } from './icons'
import React from 'react'
import { formatPercentage, getProperNoun } from './formatting'
import { LoadingDots } from './loading-dots/LoadingDots'
import { RunsListQuery } from '~/graphql-codegen-operations.gen'
import { IntelligenceVariant } from './types'
import { isPendingDiscoveryState, getVariantResults } from './util'
import semver from 'semver'
import { IntelligenceFeatureStatus } from './getIntelligenceFeatureStatus'

export type IntelligenceRunSummaryUiData = Partial<
  Pick<
    RunsListQuery['project']['runs']['nodes'][number],
    'configTestReplayEnabled' | 'isPastDataRetention' | 'status'
  >
> & {
  cypressVersion: string
  accessibilityResults?: Partial<
    RunsListQuery['project']['runs']['nodes'][number]['accessibilityResults']
  >
  uiCoverageResults?: Partial<
    RunsListQuery['project']['runs']['nodes'][number]['uiCoverageResults']
  >
}

const lockPill = (
  <div
    className="flex border-l border-gray-100 pl-[4px]"
    style={{ borderLeftStyle: 'solid' }}
  >
    <LockedMiniIcon />
  </div>
)

export type IntelligenceRunSummaryUi = {
  runTab: React.ReactNode | string
  intelligenceScoresPill: {
    value: React.ReactNode | string
    popper: string
    dataCy: string
  } | null
}

export const getIntelligenceRunSummaryUi: {
  (
    variant: IntelligenceVariant,
    run: IntelligenceRunSummaryUiData,
    feature: IntelligenceFeatureStatus
  ): IntelligenceRunSummaryUi
} = (
  variant: IntelligenceVariant,
  run: IntelligenceRunSummaryUiData,
  feature: IntelligenceFeatureStatus
) => {
  // NOTE: order of `if` statements matters!

  if (!feature.isDisplayEnabled) {
    return {
      runTab: <LockedIcon />,
      // if not enabled, the pills will all be the same so don't pollute UI
      intelligenceScoresPill: null,
    }
  }

  if (run.isPastDataRetention) {
    return {
      runTab: <LockedIcon />,
      intelligenceScoresPill: {
        value: lockPill,
        popper: 'Beyond data retention',
        dataCy: `${variant}-discovery-icon-locked`,
      },
    }
  }

  if (semver.satisfies(run.cypressVersion, '<13.0.0')) {
    return {
      runTab: <LockedIcon />,
      intelligenceScoresPill: {
        value: lockPill,
        popper: 'Cypress version not supported',
        dataCy: `${variant}-discovery-icon-locked`,
      },
    }
  }

  if (!run.configTestReplayEnabled) {
    return {
      runTab: <LockedIcon />,
      intelligenceScoresPill: {
        value: lockPill,
        popper: 'Test Replay not enabled',
        dataCy: `${variant}-discovery-icon-locked`,
      },
    }
  }

  const product = getProperNoun(variant)
  const artifact =
    variant === 'accessibility' ? 'Accessibility report' : 'UI Coverage'

  if (run.status === 'RUNNING') {
    return {
      runTab: '-',
      intelligenceScoresPill: {
        value: '-',
        popper: 'Testing in progress...',
        dataCy: `${variant}-run-processing`,
      },
    }
  }

  if (
    isPendingDiscoveryState({
      accessibilityResults: run.accessibilityResults,
      uiCoverageResults: run.uiCoverageResults,
      variant,
    })
  ) {
    return {
      runTab: '-',
      intelligenceScoresPill: {
        value: <LoadingDots />,
        popper: `${artifact} is processing...`,
        dataCy: `${variant}-discovery-processing`,
      },
    }
  }

  const coveragePercent = getVariantResults({
    accessibilityResults: run.accessibilityResults,
    uiCoverageResults: run.uiCoverageResults,
    variant,
  })?.coveragePercent

  if (typeof coveragePercent === 'number') {
    const value = formatPercentage(coveragePercent)
    return {
      runTab: value,
      intelligenceScoresPill: {
        value,
        popper: product,
        dataCy: `${variant}-score`,
      },
    }
  }

  return {
    runTab: 'N/A',
    intelligenceScoresPill: {
      value: 'N/A',
      popper: `${artifact} is not available`,
      dataCy: `${variant}-not-available`,
    },
  }
}
