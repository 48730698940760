import { rollupNetworkEvents } from '~/src/utils/rollupNetworkEvents'
import type {
  CypressAppEvent,
  ConsolePropsCommandLog,
  TestAttempt,
  ConsoleEvent,
} from '~/src/webworkers/database.worker'
import { appState } from '~/submodules'
import type { PinInfo } from '.'
import findLast from 'lodash/findLast'

export class InvalidPinError extends Error {
  constructor(message = 'Invalid PIN provided') {
    super(message)
    this.name = 'InvalidPinError'
  }
}

export const getPinInfo = (
  pinInfo: Pick<PinInfo, 'id' | 'section'>,
  args: {
    commands: TestAttempt['events']['commands']
    network: TestAttempt['events']['network']
    consoleLogs: TestAttempt['events']['consoleLogs']
    minTime: number
    maxTime: number
    forceDevToolsOpen: VoidFunction
  }
): PinInfo => {
  let snapshots: PinInfo['snapshots'] = []
  let hitbox: PinInfo['hitbox']
  if (pinInfo.section === 'command-logs') {
    const cmd: CypressAppEvent<ConsolePropsCommandLog> | undefined = findLast(
      args.commands,
      (c: CypressAppEvent<ConsolePropsCommandLog>) =>
        c.payload?.id === pinInfo.id && c.payload.snapshots
    ) as CypressAppEvent<ConsolePropsCommandLog> | undefined

    // invalid pin
    if (!cmd) {
      throw new InvalidPinError()
    }

    snapshots = cmd?.payload?.snapshots ?? []
    hitbox = cmd?.payload?.coords
    // side-effect to set pin from
    // submodule state singletons:
    appState.pinnedSnapshotId = pinInfo.id
  } else if (pinInfo.section === 'console-logs') {
    const consoleEvt = findLast(
      args.consoleLogs,
      (c: ConsoleEvent) => c.id === pinInfo.id
    )

    // invalid pin
    if (!consoleEvt) {
      throw new InvalidPinError()
    }

    snapshots = [
      {
        name: consoleEvt.summaryCount > 1 ? 'first' : undefined,
        timestamp: consoleEvt.eventStart,
        elementsToHighlight: [],
      },
    ]
    if (consoleEvt.summaryCount > 1) {
      snapshots.push({
        name: 'last',
        timestamp: consoleEvt.eventEnd,
        elementsToHighlight: [],
      })
    }
  } else if (pinInfo.section === 'network-calls') {
    const pinnedNetworkTab = args.network.find(([nId, _]) => nId === pinInfo.id)
    // invalid pin
    if (!pinnedNetworkTab) {
      throw new InvalidPinError()
    }

    if (pinnedNetworkTab) {
      const { snapshots: networkSnapshots } = rollupNetworkEvents(
        pinnedNetworkTab[1],
        {
          min: args.minTime,
          max: args.maxTime,
        }
      )
      snapshots = networkSnapshots
    }
  }
  if (['console-logs', 'network-calls'].includes(pinInfo.section)) {
    args.forceDevToolsOpen()
  }
  return {
    ...pinInfo,
    snapshots,
    hitbox,
  }
}
