import { useMemo, useEffect, useState } from 'react'
import type { AttemptHookContext } from '../useAttemptModel'
import type { GroupedLogs } from './useGroupedCommands'

/**
 * parse Reporter DOM to find and reference
 * the container node for session calls:
 */
type SessionsPanelState = {
  open: boolean
  node?: Element
  cleanup?: () => void
}
const useSessionsState = ({ attemptId }: { attemptId: number }) => {
  const [state, setState] = useState<SessionsPanelState | null>(null)

  useEffect(() => {
    // yields to main thread to allow Reporter
    // to finish rendering before we parse it:
    setTimeout(() => {
      const container = document.getElementsByClassName('sessions-container')[0]
      if (!container) {
        return
      }

      const collapsibleNode = container.getElementsByClassName('collapsible')[0]
      if (!collapsibleNode) {
        return
      }

      // we need to declare function within the closure
      // eslint-disable-next-line no-inner-declarations
      function handleToggle(event: Event) {
        let targetElement = event.target as HTMLElement
        // upwards traversal through parent nodes
        // to determine if click was within header:
        while (targetElement !== null && targetElement !== collapsibleNode) {
          if (targetElement.classList.contains('hook-header')) {
            setState((state) => ({
              ...state,
              open: !state?.open,
            }))
            return
          }
          if (targetElement.parentElement) {
            targetElement = targetElement.parentElement
          }
        }
      }

      collapsibleNode.addEventListener('click', handleToggle)

      setState({
        node: collapsibleNode,
        open: true, // we assume all sections begin expanded
        cleanup: () =>
          collapsibleNode.removeEventListener('click', handleToggle),
      })
    })

    return () => {
      state?.cleanup?.()
      setState(null)
    }
    // only called once per test-attempt mounting:
    // eslint-disable-next-line
  }, [attemptId])

  return {
    sectionState: state,
  }
}

/**
 * mutates the Reporter DOM over time so it has
 * effect of updating "session" calls over time:
 */
export const useSessionsEffect = (
  commands: GroupedLogs['command'],
  ctx: AttemptHookContext
) => {
  const { sectionState } = useSessionsState({
    attemptId: ctx.attemptNumber,
  })

  // content in the sessions panel is created from regular test-body
  // events that are also pushed into the separate summary section:
  const sessionPanelCalls = useMemo(() => {
    const calls: any[] = []
    if (!commands) {
      return calls
    }
    for (const [, data] of commands) {
      // @ts-ignore: fix
      if (data?.events?.[0]?.payload?.name === 'session') {
        // @ts-ignore: fix
        const lastEvent = data.events.at(-1)
        // only include session commands that have data
        // that gets pushed into the top sessions panel:
        // @ts-ignore: fix
        const lastStatus = lastEvent?.payload?.sessionInfo?.status
        if (lastStatus) calls.push(data)
      }
    }
    return calls
  }, [commands])

  useEffect(() => {
    // still waiting for ui to fully
    // render, or nothing to mutate:
    if (!sectionState || !sectionState.open) {
      return
    }
    // yields to main thread to allow Reporter
    // to finish rendering before we parse it:
    setTimeout(() => {
      const rows = sectionState.node?.getElementsByClassName('session-item')
      if (!rows) {
        return
      }
      for (let i = 0; i < rows.length; i++) {
        const call = sessionPanelCalls[i]
        const statusNode = rows[i]?.getElementsByClassName('session-status')[0]
        if (!statusNode) {
          continue
        }
        const ghostClass = 'tr-session-status-pending'
        if (call.start > ctx.timer.time) {
          statusNode.classList.add(ghostClass)
        } else {
          statusNode.classList.remove(ghostClass)
        }
      }
    })
  }, [ctx.timer.time, sessionPanelCalls, sectionState])
}
