import React from 'react'
import cs from 'clsx'
import moment from 'moment'
import {
  IconStatusCancelledOutline,
  IconStatusCancelledSolid,
  IconStatusErroredOutline,
  IconStatusErroredSolid,
  IconStatusFailedOutline,
  IconStatusFailedSolid,
  IconStatusPassedOutline,
  IconStatusPassedSolid,
  IconStatusRunningOutline,
  IconTechnologyBranchH,
  IconWarningCircle,
} from '@cypress-design/react-icon'
import { RunStats } from '@frontend/design-system'
import Tooltip from '@cypress-design/react-tooltip'
import type { Placement } from '@cypress-design/react-tooltip'
import { CommitAuthor } from '~/run/commit/CommitAuthor'
import { durationFormattedFull } from '~/lib/utils'
import { useRowWidthLimit } from '~/lib/hooks/useRowWidthLimit'
import {
  DiscoveryCompactionRunStatusEnum,
  Maybe,
  RunStatusEnum,
} from '~/graphql-codegen-operations.gen'

import styles from './module.RunInfoLink.scss'
import { RunGroupsAndTags, RunInfoGroup, RunInfoTag } from './RunGroupsAndTags'
import {
  IntelligenceScoresPill,
  RunSummaryUi,
} from '../intelligence/intelligence-scores-pill/IntelligenceScoresPill'
import { IntelligenceRunSummaryUiData } from '../intelligence/intelligence-utils/getIntelligenceRunSummaryUi'

export type RunInfoRun = {
  status: RunStatusEnum
  buildNumber: number
  totalPassed: number
  totalFailed: number
  totalSkipped: number
  totalPending: number
  totalFlakyTests: number
  commit: {
    authorAvatar: Maybe<string>
    authorName: Maybe<string>
    branch: Maybe<string>
    message: Maybe<string>
  }
  totalDuration: Maybe<number>
  runningDuration: Maybe<number>
  createdAt: any
  project: {
    id: string
  }
  uiCoverageResults?: {
    coveragePercent?: Maybe<number>
    status?: DiscoveryCompactionRunStatusEnum
  } | null
  accessibilityResults?: Maybe<{
    coveragePercent?: Maybe<number>
    status?: DiscoveryCompactionRunStatusEnum
  }> | null
  uniqueGroups?: Maybe<RunInfoGroup[]>
  tags?: Maybe<RunInfoTag[]>
  cypressVersion?: Maybe<string>
  isPastDataRetention?: Maybe<boolean>
  configTestReplayEnabled?: Maybe<boolean>
}

export const RunInfoLinkBase = ({
  branchName,
  className,
  children,
  href,
  openInNewTab,
  tooltipEnabled,
  tooltipContent,
  tooltipPlacement = 'bottom',
  tooltipInteractive = true,
  truncateChildren,
  isFlagged,
  /** Dark theme for the element that will be hovered and for the run number status */
  darkTheme,
  /** Dark theme for the contents inside the tooltip */
  tooltipDarkTheme,
  dataCy = 'run-info-link',
  popperClassName,
}: {
  branchName?: string
  className?: string
  children?: React.ReactNode
  href?: string
  openInNewTab?: boolean
  tooltipEnabled?: boolean
  tooltipContent?: React.ReactNode
  tooltipPlacement?: Placement
  tooltipInteractive?: boolean
  truncateChildren?: boolean
  isFlagged?: boolean
  darkTheme?: boolean
  tooltipDarkTheme?: boolean
  dataCy?: string
  popperClassName?: string
}) => {
  const InnerComponent = href
    ? (props) => (
        <a
          href={href}
          target={openInNewTab ? '_blank' : undefined}
          {...props}
        />
      )
    : (props) => <div {...props} />

  return (
    <div className={cs(styles.runInfoLink, className)}>
      <Tooltip
        popperClassName={cs(popperClassName, styles.tooltipPopper, {
          [styles.tooltipDarkTheme]: tooltipDarkTheme,
        })}
        interactive={tooltipInteractive}
        disabled={!tooltipEnabled}
        placement={tooltipPlacement}
        popper={tooltipContent}
      >
        <InnerComponent
          className={cs(styles.listItem, styles.runInfo, {
            [styles.darkTheme]: darkTheme,
          })}
          data-cy={dataCy}
        >
          {isFlagged && (
            <IconWarningCircle
              data-cy="run-info-link_flagged"
              strokeColor="gray-500"
              className={styles.flagIcon}
            />
          )}
          {children && (
            <div
              className={cs(styles.listItem)}
              style={{
                overflow: truncateChildren ? 'hidden' : undefined,
              }}
              data-cy="run-info-link_content"
            >
              {children}
            </div>
          )}
          {children && branchName && (
            <div
              className={cs(styles.divider, darkTheme && styles.darkTheme)}
            />
          )}
          {branchName && (
            <div
              data-cy="run-info-link_branch"
              className={cs(styles.listItem, styles.branchNameContainer)}
            >
              <IconTechnologyBranchH className={styles.branchIcon} />
              <span className={styles.branchName} title={branchName}>
                {branchName}
              </span>
            </div>
          )}
        </InnerComponent>
      </Tooltip>
    </div>
  )
}

function getStatusStyle(status: RunStatusEnum) {
  if (status === 'PASSED') return 'runStatusPassed'
  if (status === 'FAILED') return 'runStatusFailed'
  if (status === 'RUNNING') return 'runStatusRunning'
  if (status === 'CANCELLED') return 'runStatusCanceled'
  return 'runStatusOther'
}

export const RunStatusIcon: React.FC<{
  status: RunStatusEnum
  darkTheme?: boolean
}> = ({ status, darkTheme }) => {
  const className = cs(
    styles[`${cs(getStatusStyle(status))}`],
    styles.runStatusIcon,
    { darkTheme }
  )

  if (status === 'PASSED') {
    const Icon = darkTheme ? IconStatusPassedOutline : IconStatusPassedSolid
    return <Icon size="16" className={className} />
  }

  if (status === 'FAILED') {
    const Icon = darkTheme ? IconStatusFailedOutline : IconStatusFailedSolid

    return <Icon size="16" className={className} />
  }

  if (status === 'CANCELLED') {
    const Icon = darkTheme
      ? IconStatusCancelledOutline
      : IconStatusCancelledSolid

    return <Icon size="16" className={className} />
  }

  if (status === 'RUNNING') {
    return (
      <IconStatusRunningOutline
        className={cs(
          { [styles.darkTheme]: darkTheme },
          styles.runningIcon,
          className
        )}
        size="16"
      />
    )
  }

  // If status is none of the conditionals, then we
  // show the same error icon for all other states.
  const Icon = darkTheme ? IconStatusErroredOutline : IconStatusErroredSolid
  return <Icon size="16" className={className} />
}

export const RunTooltip: React.FC<{
  run: RunInfoRun
  /** only makes run status number container use dark theme */
  runStatusNumberDarkTheme?: boolean
  /** makes all the contents in the tooltip container use dark theme */
  tooltipDarkTheme?: boolean
  useFlakyIconV2?: boolean
  getIntelligencePillUi?: RunSummaryUi
  className?: string
  runStatsClassName?: string
}> = ({
  run,
  runStatusNumberDarkTheme,
  tooltipDarkTheme,
  useFlakyIconV2,
  getIntelligencePillUi,
  className,
  runStatsClassName,
}) => {
  const { row1Ref, row2Ref, widthLimit } = useRowWidthLimit()

  let runStatsFlakyProps = {}
  if (useFlakyIconV2) {
    runStatsFlakyProps = {
      useFlakyIconV2,
      flaky: run.totalFlakyTests,
      darkTheme: tooltipDarkTheme,
    }
  }

  return (
    <div
      data-cy="runInfoTooltip"
      className={cs(styles[`${cs(getStatusStyle(run.status))}`], className)}
    >
      <div
        className={cs(styles.tooltip, {
          [styles.tooltipDarkTheme]: tooltipDarkTheme,
        })}
      >
        <div className={cs(styles.listItem, styles.basicDetails)} ref={row1Ref}>
          <RunNumberWithStatus
            buildNumber={run.buildNumber}
            status={run.status}
            darkTheme={runStatusNumberDarkTheme}
            hasBorder
          />

          <RunStats
            passed={run.totalPassed}
            failed={run.totalFailed}
            skipped={run.totalSkipped}
            pending={run.totalPending}
            showTooltip={false}
            expanded
            className={cs(styles.runStats, runStatsClassName)}
            {...runStatsFlakyProps}
          />
          {!!run.totalFlakyTests && !useFlakyIconV2 && (
            <div className={cs(styles.listItem, styles.flakeCountBadge)}>
              <div className={styles.flakeCount}>{run.totalFlakyTests}</div>
              <div className={styles.flakeLabel}>Flaky</div>
            </div>
          )}
          {getIntelligencePillUi && run.cypressVersion !== undefined && (
            <IntelligenceScoresPill
              className={styles.intelligenceScores}
              disableLink={true}
              run={run as IntelligenceRunSummaryUiData}
              getRunSummaryUi={getIntelligencePillUi}
              basePath={`/projects/${run.project.id}/runs/${run.buildNumber}`}
              darkTheme={tooltipDarkTheme}
            />
          )}
        </div>

        {/* This row should only be as large as the 
        largest between row1Ref and row2Ref */}
        <div
          data-cy="commit-message"
          className={cs(styles.listItem, styles.commitMessage)}
          style={{ width: widthLimit }}
        >
          <span title={run.commit.message ?? undefined}>
            {run.commit.message}
          </span>
        </div>

        <div
          data-cy="commit-info"
          className={cs(styles.listItem, styles.commitAuthor)}
          ref={row2Ref}
        >
          <CommitAuthor
            shouldDisplayTooltip={false}
            authorAvatar={run.commit.authorAvatar}
            authorName={run.commit.authorName}
            withSpacing
          />
          <div className={cs(styles.dotDivider)}>·</div>
          <span
            data-cy="run-info-link-running-duration"
            className={cs(styles.elapsedTime)}
          >
            {durationFormattedFull(
              run.totalDuration ?? run.runningDuration ?? 0,
              2,
              true
            )}{' '}
            ({moment(run.createdAt).fromNow()})
          </span>
        </div>
        <RunGroupsAndTags
          uniqueGroups={run.uniqueGroups}
          tags={run.tags}
          darkTheme={tooltipDarkTheme}
        />
      </div>
    </div>
  )
}

export const RunInfoLink = ({
  run,
  branchOverride,
  runIsPending,
  tooltipDisabled,
  openInNewTab,
  darkTheme,
  className,
  isFlagged,
  dataCy,
  displayBranchName = true,
}: {
  run?: RunInfoRun | null
  branchOverride?: string
  runIsPending?: boolean
  tooltipDisabled?: boolean
  openInNewTab?: boolean
  darkTheme?: boolean
  className?: string
  isFlagged?: boolean
  dataCy?: string
  displayBranchName?: boolean
}) => {
  let branchForDisplay: string | undefined
  if (displayBranchName) {
    branchForDisplay = branchOverride || run?.commit?.branch || undefined
  }

  if (!run) {
    return (
      <RunInfoLinkBase
        branchName={branchForDisplay}
        className={cs(styles[`${getStatusStyle('RUNNING')}`], className)}
        isFlagged={isFlagged}
        darkTheme={darkTheme}
      >
        {runIsPending && (
          <div
            className={cs(styles.runNumberContainer, styles.listItem)}
            data-cy="run-info-container"
          >
            <RunStatusIcon status="RUNNING" darkTheme={darkTheme} />
          </div>
        )}
      </RunInfoLinkBase>
    )
  }

  return (
    <RunInfoLinkBase
      tooltipEnabled={!tooltipDisabled && !!run}
      tooltipContent={
        <RunTooltip run={run} runStatusNumberDarkTheme={darkTheme} />
      }
      branchName={branchForDisplay}
      href={
        run
          ? `/projects/${run.project.id}/runs/${run.buildNumber}/overview`
          : undefined
      }
      openInNewTab={openInNewTab}
      className={cs(styles[`${getStatusStyle(run.status)}`], className)}
      darkTheme={darkTheme}
      isFlagged={isFlagged}
      dataCy={dataCy}
    >
      <RunNumberWithStatus
        buildNumber={run.buildNumber}
        status={run.status}
        darkTheme={darkTheme}
      />
    </RunInfoLinkBase>
  )
}

export const RunNumberWithStatus: React.FC<{
  status: RunStatusEnum
  darkTheme?: boolean
  buildNumber: number
  hasBorder?: boolean
}> = ({ status, darkTheme, buildNumber, hasBorder }) => {
  return (
    <div
      className={cs(
        [styles.runNumberContainer, styles[`${getStatusStyle(status)}`]],
        {
          [styles.hasBorder]: hasBorder,
        }
      )}
      data-cy="run-info-container"
    >
      <RunStatusIcon status={status} darkTheme={darkTheme} />
      <span className={cs(styles.runNumber)} data-cy="run-info-number">
        #{buildNumber}
      </span>
    </div>
  )
}
