import { HorizontalList } from '@frontend/design-system'
import React from 'react'
import styles from './module.MainHeader.scss'
import {
  IconDocumentBlank,
  IconTechnologyServer,
} from '@cypress-design/react-icon'
import { TestReplayRunFragment } from '~/graphql-codegen-operations.gen'
import cs from 'clsx'
import { OSIcon } from '../osIcon/OSIcon'
import { LayeredLogoIcons } from '../layeredLogoIcons/LayeredLogoIcons'
import {
  RunInfoLinkBase,
  RunNumberWithStatus,
  RunTooltip,
} from '~/common/run-info-link/RunInfoLink'

import gql from 'graphql-tag'

gql`
  fragment TestReplayRun on Run {
    id
    status
    buildNumber
    totalPassed
    totalFailed
    totalSkipped
    totalPending
    totalFlakyTests
    commit {
      authorAvatar
      authorName
      branch
      message
    }
    totalDuration
    runningDuration
    createdAt
    project {
      id
      organizationInfo {
        id
      }
    }
  }
`

export type MainHeaderSpec = {
  title: string
  os: string
  group: string
  browser: string
}

export const MainHeader = ({
  run,
  spec,
}: // onHide,
{
  run: TestReplayRunFragment
  spec: MainHeaderSpec
  // onHide: () => void // TODO: handle redirects in the future
}) => {
  const { group, os, browser } = spec
  const capOs = `${os?.[0]?.toUpperCase() ?? ''}${os?.slice?.(1) ?? ''}`

  return (
    <HorizontalList className={styles.container}>
      <RunInfoLinkBase
        tooltipEnabled
        tooltipContent={<RunTooltip run={run} runStatusNumberDarkTheme />}
        // help prevent tooltip from leaving page:
        tooltipPlacement="bottom-end"
        className={styles.runInfoOverride}
        branchName={run?.commit?.branch || undefined}
        openInNewTab
        darkTheme
      >
        <RunNumberWithStatus
          buildNumber={run.buildNumber}
          status={run.status}
          darkTheme
        />
      </RunInfoLinkBase>
      <div
        data-cy="spec-title"
        className={cs(styles.listItem, styles.specName)}
      >
        <IconDocumentBlank fillColor="gray-900" strokeColor="gray-500" />
        <span className={styles.textPadding}>{spec.title}</span>
      </div>
      {!!group && (
        <div data-cy="spec-group" className={cs(styles.listItem)}>
          <IconTechnologyServer fillColor="gray-900" strokeColor="gray-500" />
          <span className={styles.textPadding}>{group}</span>
        </div>
      )}
      {os && (
        <div data-cy="spec-os" className={cs(styles.listItem, styles.os)}>
          <OSIcon os={capOs} />
          <span className={styles.textPadding}>{capOs}</span>
        </div>
      )}
      {browser && (
        <div
          data-cy="spec-browser"
          className={cs(styles.listItem, styles.browser)}
        >
          <LayeredLogoIcons browsers={[browser]} />
          <span className={styles.textPadding}>{browser}</span>
        </div>
      )}
    </HorizontalList>
  )
}
