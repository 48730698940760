import { assign, isArray } from 'lodash'
import { action, computed, observable } from 'mobx'
import {
  SpecTestResultFragment,
  RunGroup,
  OperatingSystem,
  BrowserInfo,
  SpecFile,
  Screencast,
  RunInstanceStatusEnum,
} from '~/graphql-codegen-operations.gen'

export default class Spec {
  @observable id
  @observable claimedAt
  @observable createdAt
  @observable completedAt
  @observable totalFailed
  @observable totalPassed
  @observable totalPending
  @observable totalSkipped
  @observable duration
  @observable group: RunGroup = {} as RunGroup
  @observable error
  @observable parallelEnabled
  @observable status: RunInstanceStatusEnum | undefined
  @observable hasStdout
  @observable testingType
  @observable spec: SpecFile = {} as SpecFile
  @observable browser: BrowserInfo = {} as BrowserInfo
  @observable os: OperatingSystem = {} as OperatingSystem
  @observable video: Screencast = {} as Screencast
  @observable postProcessingCompletedAt
  @observable machineId
  @observable totalFlakyTests
  @observable wallClockEndedAt
  @observable wallClockStartedAt
  @observable estimatedWallClockDuration
  @observable prioritizedByFailedSpecs

  @observable areTestsLoaded
  @observable isReadyForAnalysis = false
  @observable tests: SpecTestResultFragment[] = []
  @observable machineIndex

  @observable isVisibleInSpecsList

  constructor(attrs) {
    this.update(attrs)
  }

  @action update(attrs) {
    assign(this, attrs)

    if (attrs.postProcessingCompletedAt) {
      this.isReadyForAnalysis = true
    }

    if (
      attrs.status === 'NOTESTS' ||
      attrs.status === 'ERRORED' ||
      attrs.status === 'CANCELLED' ||
      attrs.status === 'TIMEDOUT'
    ) {
      this.isReadyForAnalysis = true
      this.areTestsLoaded = true
    }
  }

  @action setIsVisibleInSpecsList(isVisible) {
    this.isVisibleInSpecsList = isVisible
  }

  @action setTests(attrs) {
    if (attrs.tests && isArray(attrs.tests) && this.tests.length === 0) {
      this.tests = attrs.tests
      this.areTestsLoaded = true
    }

    if (attrs.postProcessingCompletedAt && !this.postProcessingCompletedAt) {
      this.postProcessingCompletedAt = attrs.postProcessingCompletedAt
      this.isReadyForAnalysis = true
    }
  }

  @computed get totalProcessingDuration() {
    return (
      +new Date(this.postProcessingCompletedAt) -
      +new Date(this.wallClockStartedAt)
    )
  }
}
