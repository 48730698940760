import React, { useEffect, useState } from 'react'

// Helps track how long the DOM node is in view
// and when to abort the clock if node is hidden.
export const useTimer = ({
  timeout,
  abort,
}: {
  timeout: number
  abort: boolean
}) => {
  const [timerDone, setTimerDone] = useState(false)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!timerDone) {
        setTimerDone(true)
      }
    }, timeout)
    if (abort) {
      clearTimeout(timeoutId)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [timeout, abort, timerDone])
  return { timerDone }
}

export const CalculatingStatus = () => <p>Calculating...</p>
export const RunningStatus = () => <p>Run in progress...</p>
