import { useEffect, useState } from 'react'
import { isEitherIntelligenceProductPendingDiscoveryState } from '~/common/intelligence/intelligence-utils/util'
import {
  RunContainerQuery,
  useRunContainerIntelligenceLazyQuery,
} from '~/graphql-codegen-operations.gen'

export function usePollForRunContainerIntelligence(arg: {
  run?: RunContainerQuery['run']
  accessibilityFlagEnabled: boolean
  interactivityFlagEnabled: boolean
}) {
  const { run, accessibilityFlagEnabled, interactivityFlagEnabled } = arg
  const intelligenceEnabled =
    (accessibilityFlagEnabled || interactivityFlagEnabled) &&
    run?.configTestReplayEnabled
  const buildId = run?.id
  const [pollingAttempt, setAttempt] = useState(0)
  const [executeQuery, intelligenceQueryResult] =
    useRunContainerIntelligenceLazyQuery()

  const needsToPoll = intelligenceQueryResult.data?.run
    ? isEitherIntelligenceProductPendingDiscoveryState(
        intelligenceQueryResult.data.run
      )
    : run
      ? isEitherIntelligenceProductPendingDiscoveryState(run)
      : false

  const isRunStillRunning = run?.status === 'RUNNING'

  useEffect(() => {
    let t: ReturnType<typeof setTimeout> | undefined
    // If intelligence features are enabled, and
    // we've received a non-null discovery payload, and
    // the discovery payload is in a pending state, then
    // the polling will continue until completion or error.
    // intelligenceEnabled &&
    if (intelligenceEnabled && buildId && needsToPoll) {
      executeQuery({
        variables: {
          buildId,
          interactivityFlagEnabled,
          accessibilityFlagEnabled,
        },
      })
      t = setTimeout(() => {
        setAttempt(pollingAttempt + 1)
      }, 5000)
    }
    return () => {
      clearTimeout(t)
    }
  }, [
    isRunStillRunning,
    needsToPoll,
    intelligenceEnabled,
    interactivityFlagEnabled,
    accessibilityFlagEnabled,
    buildId,
    executeQuery,
    pollingAttempt,
  ])
  return intelligenceQueryResult.data
}
