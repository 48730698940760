import cx from 'clsx'
import React from 'react'
import styles from './module.ContentLoader.scss'

interface ContentLoaderProps {
  className?: string
  primaryColor?: string
  secondaryColor?: string
}

export const ContentLoader = ({
  className,
  primaryColor = '#f3f4fa', // gray-50
  secondaryColor = '#e1e3ed', // gray-100
}: ContentLoaderProps = {}) => {
  const style = {
    backgroundImage: `linear-gradient(to right, ${primaryColor} 0%, ${primaryColor} 35%, ${secondaryColor} 50%, ${primaryColor} 65%, ${primaryColor} 100%)`,
  }

  return <div style={style} className={cx(styles.contentLoader, className)} />
}
