import _ from 'lodash'
import { action } from 'mobx'
import moment from 'moment'

import localData from '../lib/local-data'

const getBanner = (bannerName) => {
  return localData.get(bannerName) || {}
}

const isClosed = (bannerName, projectId) => {
  const banner = getBanner(bannerName)

  return banner[projectId] === 'closed'
}

class SpecsStore {
  @action closeProjectBanner(bannerName, projectId) {
    // synchronize this to local storage
    const banner = getBanner(bannerName)

    banner[projectId] = 'closed'
    localData.set(bannerName, banner)
  }

  shouldShowBanner(bannerName, projectId) {
    // we should show the project banner if this
    // is not closed
    return !isClosed(bannerName, projectId)
  }

  getAndSortSpecsTimeStamp = (specs = [], timeStampString = '') => {
    return _(specs)
      .map((spec) => {
        const timestamp = spec[timeStampString]

        return new Date(timestamp).valueOf()
      })
      .compact()
      .orderBy([_.identity], ['asc'])
      .value()
  }

  getDurationBetweenFirstStartAndLastEnd(startTimes = [], endTimes = []) {
    if (!(startTimes.length && endTimes.length)) return null

    const startTime = _.first(startTimes)
    const endTime = _.last(endTimes)

    return Math.abs(moment(startTime).diff(moment(endTime)))
  }

  getStatus(specs, options = {}) {
    if (
      options.includeCanceledStatus &&
      _.some(specs, { status: 'CANCELLED' })
    ) {
      return 'CANCELLED'
    }

    if (_.every(specs, { status: 'UNCLAIMED' })) {
      return 'UNCLAIMED'
    }

    if (
      _.some(specs, { status: 'RUNNING' }) ||
      _.some(specs, { status: 'UNCLAIMED' })
    ) {
      return 'RUNNING'
    }

    if (_.some(specs, { status: 'FAILED' })) {
      return 'FAILED'
    }

    if (_.some(specs, 'error')) {
      return 'ERRORED'
    }

    if (_.some(specs, { status: 'TIMEDOUT' })) {
      return 'TIMEDOUT'
    }

    return 'PASSED'
  }

  getDuration(machines) {
    const durations = _.map(machines, (machine) => {
      return _.sumBy(machine.specs, 'duration')
    })

    return Math.max(...durations)
  }

  getAvgWallClockDuration(specs) {
    return _.chain(specs)
      .map('duration')
      .compact()
      .thru((arr) => {
        if (!arr.length) {
          return null
        }

        return _.chain(arr).sum().divide(arr.length).round(1)
      })
      .value()
  }
}

export default new SpecsStore()
