import { FormGroup, FormGroupProps, Tooltip } from '@frontend/design-system'
import { Field, FieldProps } from 'formik'
import * as React from 'react'

import { InputField, InputFieldProps } from './InputField'

type DefaultInputProps<TFormValues> = FieldProps<TFormValues> & {
  suppressError?: boolean
}

type FormGroupFieldProps<
  TFormValues extends {},
  TInputProps extends DefaultInputProps<TFormValues>,
  TName extends keyof TFormValues = keyof TFormValues,
> = Pick<FormGroupProps, 'label' | 'description'> & {
  name: TName
  disabled?: boolean
  editable?: boolean
  tooltipInfo?: React.ReactNode
  formatReadonlyValue?: (value: TFormValues[TName]) => React.ReactNode
  InputComponent?: React.ComponentType<TInputProps>
  InputProps?: Omit<TInputProps, keyof FieldProps>
  className?: string
}

const defaultReadonlyFormatter = (value: unknown) => {
  if (typeof value === 'string' || typeof value === 'number') {
    return value
  }
  /* istanbul ignore next */

  console.warn(
    'Cannot display readonly value in the FormGroupField, please pass formatReadonlyValue'
  )
  /* istanbul ignore next */
  return 'unknown'
}

export const FormGroupField = <
  TFormValues extends {} = {},
  TInputProps extends DefaultInputProps<TFormValues> = InputFieldProps,
>({
  name,
  disabled,
  label,
  editable,
  tooltipInfo,
  description,
  InputProps,
  formatReadonlyValue = defaultReadonlyFormatter,
  className,
  // @ts-ignore some weird prop-types WeakValidationMapError
  InputComponent = InputField,
  ...rest
}: FormGroupFieldProps<TFormValues, TInputProps>) => {
  return (
    <Field disabled={disabled} name={name}>
      {(fieldProps) => {
        const error = fieldProps.form.errors[name]
        const toShowError = Boolean(fieldProps.form.touched[name] && error)

        return (
          <FormGroup
            label={label}
            description={description}
            error={toShowError}
            errorMessage={toShowError ? error : undefined}
            className={className}
            {...rest}
          >
            {editable ? (
              <InputComponent
                suppressError
                disabled={disabled}
                {...fieldProps}
                {...InputProps}
              />
            ) : (
              <div>
                {formatReadonlyValue(fieldProps.field.value)}{' '}
                {tooltipInfo && (
                  <Tooltip placement="right" overlay={tooltipInfo}>
                    <i className="fa fa-info-circle" />
                  </Tooltip>
                )}
              </div>
            )}
          </FormGroup>
        )
      }}
    </Field>
  )
}
