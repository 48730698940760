import React from 'react'
import pluralize from 'pluralize'
import { ConfigurationRow } from './ConfigRow'
import { RunDetails, getParallelizationPercents } from '../../helpers'
import { useRunRecommendationsRawDataQuery } from '~/graphql-codegen-operations.gen'
import { Chip } from '@frontend/design-system'
import './RunDurationTooltip.scss'
import { useTimer, RunningStatus, CalculatingStatus } from './utils'

export const TAG_RENDER_LIMIT = 2
const NOT_APPLICABLE = 'Not applicable'

export const Parallelization: React.FC<{
  runDetails: RunDetails
  visible: boolean
}> = ({ runDetails, visible }) => {
  const isRunning = runDetails.status === 'RUNNING'

  // Waits a bit of time for user to keep tooltip node
  // within view before we attempt network request:
  const { timerDone } = useTimer({
    abort: !visible,
    timeout: 350,
  })

  const { data: rawRecommendationsData, loading } =
    useRunRecommendationsRawDataQuery({
      skip: !timerDone || isRunning,
      variables: { buildId: runDetails.id },
    })

  // Only displays parallelization recommendation once the Run
  // has timerDone a terminal state or network requests have
  // completed, otherwise we simply show "calculating...":
  const inProgress = !timerDone || loading || isRunning

  const percents = React.useMemo(() => {
    if (!rawRecommendationsData) {
      // Still waiting for network response,
      // just return empty array while we wait:
      return []
    }

    // Merge base run with "rawRecommendationsData"
    // required for recommendations calculations:
    return getParallelizationPercents({
      ...runDetails,
      ...rawRecommendationsData!.run,
      instances: {
        ...runDetails.instances,
        ...rawRecommendationsData?.run.instances,
      },
    })
  }, [runDetails, rawRecommendationsData])

  const { uniqueGroups, uniqueGroupsCount } = runDetails

  const singleGroupPercent =
    uniqueGroupsCount === 1
      ? percents.find((p) => p.groupName === uniqueGroups[0].name)?.percent
      : null

  const notApplicableToSingle = typeof singleGroupPercent !== 'number'

  const singleGroupColor = notApplicableToSingle
    ? 'gray'
    : (singleGroupPercent as number) > 0
      ? 'jade'
      : 'red'

  const singleGroupValue =
    uniqueGroupsCount > 1
      ? undefined
      : notApplicableToSingle
        ? NOT_APPLICABLE
        : `${singleGroupPercent}%`

  const renderLimit =
    uniqueGroupsCount > 3 ? TAG_RENDER_LIMIT : uniqueGroupsCount
  const diffGroupsFromLimit = runDetails.uniqueGroupsCount - TAG_RENDER_LIMIT

  const renderHeader = () => {
    return (
      <ConfigurationRow
        data-cy="ParallelizationRoot"
        label="Parallelization"
        value={singleGroupValue}
        valueOverride={
          isRunning ? (
            <RunningStatus />
          ) : !timerDone || loading ? (
            <CalculatingStatus />
          ) : undefined
        }
        color={inProgress ? undefined : singleGroupColor}
      />
    )
  }

  const renderGroupDetails = () => {
    return (
      uniqueGroupsCount > 1 &&
      uniqueGroups.slice(0, renderLimit).map((g) => {
        const parallelPercent = percents.find(
          (p) => p.groupName === g.name
        )?.percent

        const notApplicableHere = typeof parallelPercent !== 'number'

        const value = notApplicableHere ? NOT_APPLICABLE : `${parallelPercent}%`

        const color = notApplicableHere
          ? 'gray'
          : (parallelPercent as number) > 0
            ? 'jade'
            : 'red'

        return (
          <div className="group-row config-group" key={g.name}>
            <p className="group-name group-border" title={g.name || 'Unnamed'}>
              {g.name || <i>Unnamed</i>}
            </p>
            {['UNCLAIMED'].includes(g.status) ? (
              <div className="smart-orch-loading">
                <p>{g.machineCount}x</p>
              </div>
            ) : (
              <Chip value={value} color={color} />
            )}
          </div>
        )
      })
    )
  }

  const renderHiddenGroups = () => {
    return (
      diffGroupsFromLimit > 1 && (
        <div className="hidden-groups-count">
          <p className="count-delta-chip">+{diffGroupsFromLimit}</p>
          <p>{pluralize('group', diffGroupsFromLimit)} hidden</p>
        </div>
      )
    )
  }

  const renderBody = () => {
    return (
      <div className="parallelization-body">
        {renderGroupDetails()}
        {renderHiddenGroups()}
      </div>
    )
  }

  return (
    <div className="parallelization-row">
      {renderHeader()}
      {!inProgress && renderBody()}
    </div>
  )
}
