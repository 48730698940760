import { assign } from 'lodash'
import { observable } from 'mobx'

export default class Group {
  @observable id
  @observable name
  @observable browser = {}
  @observable os = {}
  @observable status
  @observable failed
  @observable passed
  @observable pending
  @observable skipped
  @observable muted
  @observable numMachines
  @observable sumDuration
  @observable groupDuration
  @observable specs = []
  @observable testingType

  constructor(options) {
    assign(this, options)
  }
}
