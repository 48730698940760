import { IconObjectTag, IconTechnologyServer } from '@cypress-design/react-icon'
import React from 'react'
import { TAG_RENDER_LIMIT } from '~/overview/RunAnalytics/Chart/RunDurationTooltip/Parallelization'
import styles from './module.RunGroupsAndTags.scss'
import cs from 'clsx'

export type RunInfoGroup = {
  name: string
}

export type RunInfoTag = {
  name: string
}

const filterInvalidNames = (
  elements?: (RunInfoGroup | RunInfoTag)[] | null
) => {
  return (
    elements?.filter((element) => element.name && element.name !== '') ?? []
  )
}

export const RunGroupsAndTags = ({
  uniqueGroups,
  tags,
  darkTheme,
}: {
  uniqueGroups?: RunInfoGroup[] | null
  tags?: RunInfoTag[] | null
  darkTheme?: boolean
}) => {
  const validRunGroups = filterInvalidNames(uniqueGroups)
  const validTags = filterInvalidNames(tags)

  if (!validRunGroups.length && !validTags.length) {
    return null
  }

  const validTagsCount = validTags.length
  const validRunGroupsCount = validRunGroups.length

  const renderHiddenElementsTag = (count?: number) => {
    let diffElementsFromLimit
    if (count !== undefined && count !== null) {
      diffElementsFromLimit = count - TAG_RENDER_LIMIT
    }
    return (
      diffElementsFromLimit > 1 && (
        <div data-cy="hidden-element-tag" className={styles.elements}>
          +{diffElementsFromLimit}
        </div>
      )
    )
  }

  const renderElements = (
    elements?: (RunInfoGroup | RunInfoTag)[],
    count?: number
  ) => {
    const renderLimit = count && count > 3 ? TAG_RENDER_LIMIT : count

    const elementsWithValidNames = elements?.filter(
      (element) => element.name && element.name !== ''
    )

    return elementsWithValidNames?.slice(0, renderLimit).map((element) => {
      return (
        <div
          key={element.name}
          data-cy="element-tag"
          className={styles.elements}
        >
          {element.name}
        </div>
      )
    })
  }

  return (
    <div className={cs({ [styles.darkTheme]: darkTheme })}>
      <hr className={styles.divider} />
      <div className={styles.container}>
        {!!validTagsCount && (
          <div className={cs(styles.row)} data-cy="run-info-groups">
            <IconObjectTag
              fillColor={darkTheme ? 'gray-800' : 'gray-50'}
              strokeColor={darkTheme ? 'gray-400' : 'gray-500'}
            />
            {renderElements(validTags, validTagsCount)}
            {renderHiddenElementsTag(validTagsCount)}
          </div>
        )}
        {!!validRunGroupsCount && (
          <div className={cs(styles.row)} data-cy="run-info-tags">
            <IconTechnologyServer
              fillColor={darkTheme ? 'gray-800' : 'gray-50'}
              strokeColor={darkTheme ? 'gray-400' : 'gray-500'}
            />
            {renderElements(validRunGroups, validRunGroupsCount)}
            {renderHiddenElementsTag(validRunGroupsCount)}
          </div>
        )}
      </div>
    </div>
  )
}
